<div class="check-in-code-wrapper">
  <h1>{{ langService.dictionary["check-in-dialog"][langService.lang] }}</h1>
  <custom-input
    [type]="'text'"
    [(ngModel)]="code"
    [label]="langService.dictionary['check-in-id'][langService.lang]"
  ></custom-input>
  <button (click)="checkCode()" class="primary-button">
    {{ langService.dictionary["next"][langService.lang] }}
  </button>
</div>
