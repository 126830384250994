<div class="room-edit-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["modify"][langService.lang] }}</h1>
    <!-- <p>Itt módosíthatja a szobák adatait</p> -->
  </div>
  <div class="hint" *ngIf="!roomService.selectedRoom">
    <span>{{ langService.dictionary["select-room"][langService.lang] }}</span>
  </div>
  <div class="form-wrapper" [hidden]="!roomService.selectedRoom">
    <form #roomForm="ngForm">
      <ng-container *ngIf="roomService.selectedRoom">
        <custom-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.name"
          [label]="langService.dictionary['room-name'][langService.lang] + '*'"
          [maxlength]="32"
        />
        <custom-input
          [type]="'number'"
          id="room_number"
          name="room_number"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.room_number"
          [label]="
            langService.dictionary['room-number'][langService.lang] + '*'
          "
        />
        <custom-select
          id="type"
          name="type"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.type"
          [options]="roomTypeOptions"
          [label]="langService.dictionary['room-type'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'text'"
          id="building"
          name="building"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.building"
          [label]="
            langService.dictionary['room-building'][langService.lang] +
            '* ' +
            langService.dictionary['room-building-example'][langService.lang]
          "
          [maxlength]="32"
        />
        <custom-select
          id="floor"
          name="floor"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.floor"
          [options]="floorOptions"
          [label]="langService.dictionary['floor'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          id="single_beds"
          name="single_beds"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.single_beds"
          [label]="
            langService.dictionary['single-beds'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          id="double_beds"
          name="double_beds"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.double_beds"
          [label]="
            langService.dictionary['double-beds'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          id="extra_beds"
          name="extra_beds"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.extra_beds"
          [label]="langService.dictionary['extra-beds'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          id="couch_beds"
          name="couch_beds"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.couch_beds"
          [label]="langService.dictionary['couch-beds'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          id="max_adults"
          name="max_adults"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.max_adults"
          [label]="langService.dictionary['max-adults'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          id="max_family_adults"
          name="max_family_adults"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.max_family_adults"
          [label]="
            langService.dictionary['max-family-adults'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          id="max_family_children"
          name="max_family_children"
          [required]="true"
          [(ngModel)]="roomService.selectedRoom.max_family_children"
          [label]="
            langService.dictionary['max-family-children'][langService.lang] +
            '*'
          "
        />
        <div class="row-wrapper">
          <custom-input
            [type]="'number'"
            id="default_price_huf"
            name="default_price_huf"
            [required]="true"
            [(ngModel)]="roomService.selectedRoom.default_price_huf"
            [label]="
              langService.dictionary['room-price'][langService.lang] + ' HUF*'
            "
          />
          <custom-checkbox
            id="ifa_included"
            name="ifa_included"
            [name]="'ifa_included'"
            [label]="langService.dictionary['includes-IFA'][langService.lang]"
            [(ngModel)]="roomService.selectedRoom.ifa_included"
          />
        </div>
        <custom-checkbox
          id="air_conditioning"
          name="air_conditioning"
          [name]="'air_conditioning'"
          [label]="langService.dictionary['air-conditioning'][langService.lang]"
          [(ngModel)]="roomService.selectedRoom.air_conditioning"
        />
        <custom-checkbox
          id="wheelchair_accessible"
          name="wheelchair_accessible"
          [name]="'wheelchair_accessible'"
          [label]="
            langService.dictionary['wheelchair-accessible'][langService.lang]
          "
          [(ngModel)]="roomService.selectedRoom.wheelchair_accessible"
        />
      </ng-container>
    </form>
  </div>

  <div class="button-wrapper" *ngIf="roomService.selectedRoom">
    <button class="secondary-button" type="button" (click)="deselectRoom()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="primary-button"
      type="submit"
      [disabled]="roomForm?.invalid"
      (click)="editRoom()"
    >
      {{ langService.dictionary["modify"][langService.lang] }}
    </button>
  </div>
</div>
