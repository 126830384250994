import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { errorCodes } from '../@consts';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { OfflineService } from './offline.service';
import { RouterService } from './router.service';
import { SnackbarService } from './snackbar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private snackBarService: SnackbarService,
    private http: HttpClient,
    private router: Router,
    private offlineService: OfflineService,
    private routerService: RouterService,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  private expireTime = 3300000;
  private expireTimer: any;
  private authRegex: RegExp = new RegExp(`\\bapi/auth/refresh\\b`, 'i');
  private readonly errorCodes: any = errorCodes;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (
            event instanceof HttpResponse &&
            request.method !== 'GET' &&
            !this.authRegex.test(request.url)
          ) {
            this.handleResponse();
          }
          if (event instanceof HttpResponse) {
            if (this.expireTimer) {
              clearTimeout(this.expireTimer);
            }
            this.expireTimer = setTimeout(() => {
              this.http
                .post(`${this.configService.server}api/auth/refresh`, {})
                .subscribe({ next: () => {}, error: () => {} });
            }, this.expireTime);
          }
        },
        error: (httpError: HttpErrorResponse) => {
          if (
            httpError instanceof HttpErrorResponse &&
            request.method !== 'GET'
          ) {
            this.handleError(httpError.error);
          }
          if (httpError?.status === 401) {
            console.log('401 err');
            this.authService.IsAuthenticated = false;
            if (this.router.url.split('?')[0] !== '/register') {
              console.log('url ', this.router.url);

              this.routerService.absoluteNavigation('login');
            }
          }
        },
      })
    );
  }

  private handleResponse(): void {
    // Call your service function for successful response handling
    this.snackBarService.openOkSnackBar();
  }

  private handleError(errorObject: any): void {
    // Call your service function for error handling

    const errorMessage =
      typeof errorObject.error === 'number'
        ? this.errorCodes[errorObject.error]
        : errorObject.error;

    this.snackBarService.openFailedSnackBar(errorMessage);
  }
}
