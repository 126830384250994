import { Injectable } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BookingInterface } from '../@Interfaces';
import { BookingService } from './booking.service';
import { CalendarService } from './calendar.service';
import { DateService } from './date.service';
import { HotelService } from './hotel.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor(
    private bookingService: BookingService,
    private dateService: DateService,
    private roomService: RoomService,
    private hotelService: HotelService,
    private calendarService: CalendarService
  ) {}

  private splicedBookings: BookingInterface[] = [];
  public roomIdForCalendar: number;

  public handleTableClick(
    room_id: number,
    date: string,
    startClick?: boolean
  ): void {
    if (
      !this.bookingService.sidePanelRooms[room_id]?.arrival_date &&
      !this.bookingService.sidePanelRooms[room_id]?.departure_date
    ) {
      if (startClick) {
        this.bookingService.sidePanelRooms[room_id] = {
          arrival_date: date,
          departure_date: this.dateService.addDayToStringDate(date, 1),
          adults: 0,
          children: 0,
          gross_price: 0,
          departed: false,
          paid: false,
          arrived: false,
          room_id: room_id,
          contact_name: this.bookingService.bookingData.contact_name,
          ifa_included: false,
        };
      } else {
        this.bookingService.sidePanelRooms[room_id] = {
          arrival_date: this.dateService.addDayToStringDate(date, -1),
          departure_date: date,
          adults: 0,
          children: 0,
          gross_price: 0,
          departed: false,
          paid: false,
          arrived: false,
          room_id: room_id,
          contact_name: this.bookingService.bookingData.contact_name,
          ifa_included: false,
        };
      }

      return;
    }

    if (
      this.bookingService.sidePanelRooms[room_id]?.arrival_date === date ||
      this.bookingService.sidePanelRooms[room_id]?.departure_date === date
    ) {
      //clicked date same as end or start

      return;
    }

    if (this.bookingService.sidePanelRooms[room_id].arrived) {
      return;
    }
    this.arrangeDates(date, room_id);
  }

  public handleDatePicker(
    roomId: string,
    arrivalDate: MatDatepickerInputEvent<any, any>,
    isStartDate?: boolean
  ): void {
    const formatedDate = this.dateService.formatDate(arrivalDate.value);

    if (!this.bookingService.sidePanelRooms[roomId]) {
      this.handleTableClick(Number(roomId), formatedDate, isStartDate);

      return;
    }

    if (isStartDate) {
      this.bookingService.sidePanelRooms[Number(roomId)].arrival_date =
        formatedDate;
    } else {
      this.bookingService.sidePanelRooms[Number(roomId)].departure_date =
        formatedDate;
    }

    this.checkSelectedDate(
      roomId,
      this.bookingService.sidePanelRooms[Number(roomId)].arrival_date,
      this.bookingService.sidePanelRooms[Number(roomId)].departure_date,
      isStartDate
    );
  }

  public handleDatePickerV2(
    roomId: string,
    arrivalDate: any,
    isStartDate?: boolean
  ): void {
    let stringDate: string;
    if (arrivalDate instanceof Date) {
      stringDate = this.dateService.formatDate(arrivalDate);
    } else {
      stringDate = arrivalDate;
    }

    if (!this.bookingService.sidePanelRooms[roomId]) {
      this.handleTableClick(Number(roomId), stringDate, isStartDate);
      return;
    }

    if (isStartDate) {
      this.bookingService.sidePanelRooms[Number(roomId)].arrival_date =
        stringDate;
      this.calendarService.inputStartDate = new Date(arrivalDate);
      this.calendarService.setCalendarDays();
    } else {
      this.bookingService.sidePanelRooms[Number(roomId)].departure_date =
        stringDate;
    }

    this.bookingService.sidePanelRooms[Number(roomId)].gross_price =
      this.calculateRoomPrice(
        roomId,
        this.bookingService.sidePanelRooms[Number(roomId)].arrival_date,
        this.bookingService.sidePanelRooms[Number(roomId)].departure_date
      ) ?? this.bookingService.sidePanelRooms[Number(roomId)].gross_price;

    if (
      this.hotelService?.selectedHotel?.prepayment_enabled &&
      this.bookingService.sidePanelRooms[Number(roomId)].gross_price
    ) {
      this.bookingService.sidePanelRooms[Number(roomId)].prepayment =
        this.getPrePaymentAmount(
          this.bookingService.sidePanelRooms[Number(roomId)].gross_price
        );
    }

    this.checkSelectedDate(
      roomId,
      this.bookingService.sidePanelRooms[Number(roomId)].arrival_date,
      this.bookingService.sidePanelRooms[Number(roomId)].departure_date,
      isStartDate
    );
  }

  public loadRoomBookings(bookingId: number): void {
    this.bookingService.bookings.map((booking) => {
      if (booking.booking_id === bookingId) {
        this.splicedBookings.push(booking);
        this.bookingService.sidePanelRooms[booking.room_id] = {
          arrival_date: booking.arrival_date,
          departure_date: booking.departure_date,
          adults: booking.adults,
          children: booking.children,
          gross_price: booking.gross_price,
          departed: booking.departed,
          paid: booking.paid,
          arrived: booking.arrived,
          room_id: booking.room_id,
          contact_name: booking.contact_name,
          booking_id: booking.booking_id,
          ifa_included: booking.ifa_included,
        };
      }
    });
    //TODO multiple from the same gets loaded console.log('loaded ', this.bookingService.sidePanelRooms);
  }

  public addRoom(newRoomId: number): void {
    this.bookingService.sidePanelRooms['newRoom_' + newRoomId] = {
      arrival_date: null,
      departure_date: null,
      adults: 0,
      children: 0,
      gross_price: 0,
      departed: false,
      paid: false,
      arrived: false,
      room_id: 0,
      newRoom: true,
      contact_name: this.bookingService.bookingData.contact_name,
      ifa_included: null,
      prepayment: 0,
    };
  }

  public removeRoom(roomId: string): void {
    delete this.bookingService.sidePanelRooms[roomId];
  }

  public clearRoomBookings(): void {
    this.bookingService.sidePanelRooms = {};
  }

  getBookedDayFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    const modifiedDate = new Date(date);
    const bookingId = this.bookingService?.selectedBooking?.booking_id ?? 0;
    return this.roomService.isRoomAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate() + 1)),
      this.roomIdForCalendar,
      this.bookingService.bookings,
      bookingId
    );
  };

  getBookedDayFilterDeparture = (date: Date): boolean => {
    //won't allow to pick sooner date than start date
    if (!date) {
      return false;
    }
    if (
      this.bookingService.sidePanelRooms[this.roomIdForCalendar]?.arrival_date
    ) {
      const arrDate = new Date(
        this.bookingService.sidePanelRooms[this.roomIdForCalendar].arrival_date
      ).getTime();
      if (arrDate > date.getTime()) {
        return false;
      }
    }
    const modifiedDate = new Date(date);
    return this.roomService.isRoomAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate())),
      this.roomIdForCalendar,
      this.bookingService.bookings,
      this.bookingService.selectedBooking.booking_id
    );
  };

  groupDateFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    const rooms = Object.keys(this.bookingService.sidePanelRooms);
    const modifiedDate = new Date(date);
    return this.roomService.areRoomsAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate() + 1)),
      rooms,
      this.bookingService.bookings
    );
  };

  public validateBookings(): boolean {
    let isValid: boolean = true;

    if (!Object.keys(this.bookingService.sidePanelRooms).length) return false;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking.adults + booking.children <= 0) {
        isValid = false;
      }
      if (booking.gross_price <= 0) {
        isValid = false;
      }
      if (!booking.arrival_date || !booking.departure_date) {
        isValid = false;
      }
      if (booking.gross_price <= booking.prepayment) {
        isValid = false;
      }
      if (
        this.dateService.isBookedBetween(
          booking.room_id,
          booking.arrival_date,
          booking.departure_date,
          this.bookingService.bookings,
          booking.booking_id
        )
      ) {
        isValid = false;
      }
    });

    return isValid;
  }

  /* private isStartDateAfterEndDate(selectedDates: {
    startDate: string;
    endDate: string;
  }): boolean {
    return new Date(selectedDates.startDate) > new Date(selectedDates.endDate);
  } */

  private arrangeDates(clickedDate: string, roomId: number): void {
    const arrival_date = new Date(
      this.bookingService.sidePanelRooms[roomId].arrival_date
    );
    const departure_date = new Date(
      this.bookingService.sidePanelRooms[roomId].departure_date
    );
    const date = new Date(clickedDate);

    const arrivalDiff = Math.abs(date.getTime() - arrival_date.getTime());
    const departureDiff = Math.abs(date.getTime() - departure_date.getTime());

    if (arrivalDiff < departureDiff) {
      if (
        this.dateService.isBookedBetween(
          roomId,
          clickedDate,
          this.bookingService.sidePanelRooms[roomId].departure_date,
          this.bookingService.bookings,
          this.bookingService.selectedBooking.booking_id
        )
      ) {
        return;
      }

      this.bookingService.sidePanelRooms[roomId].arrival_date = clickedDate;
    } else {
      if (
        this.dateService.isBookedBetween(
          roomId,
          this.bookingService.sidePanelRooms[roomId].arrival_date,
          clickedDate,

          this.bookingService.bookings,
          this.bookingService.selectedBooking.booking_id
        )
      ) {
        return;
      }

      this.bookingService.sidePanelRooms[roomId].departure_date = clickedDate;
    }
  }

  private checkSelectedDate(
    roomId: string,
    arrivalDate: string,
    departureDate: string,
    isStartDate?: boolean
  ): void {
    if (this.dateService.isDateSooner(arrivalDate, departureDate)) {
      return;
    }
    if (isStartDate) {
      this.bookingService.sidePanelRooms[roomId].departure_date =
        this.dateService.addDayToStringDate(arrivalDate, 1);
    } else {
      this.bookingService.sidePanelRooms[roomId].arrival_date =
        this.dateService.addDayToStringDate(departureDate, -1);
    }
  }

  public calculateRoomPrice(
    roomId: string,
    arrivalDate: string,
    departureDate: string
  ): number {
    const selectedRoom = this.roomService.rooms.find(
      (room) => room.room_id == Number(roomId)
    );

    if (!selectedRoom || !arrivalDate || !departureDate) return null;

    const d1 = new Date(arrivalDate);
    const d2 = new Date(departureDate);

    const diffInTime = Math.abs(d2.getTime() - d1.getTime());
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    return diffInDays * selectedRoom.default_price_huf;
  }

  private getPrePaymentAmount(defaultPrice: number): number {
    const flat = this.hotelService.selectedHotel.prepayment_flat;
    if (flat) return flat;

    const mult = this.hotelService.selectedHotel.prepayment_percent / 100;

    const price = defaultPrice * mult;
    return price;
  }
}
