<div class="booking-rooms-wrapper" (click)="keepWindowOpen($event)">
  <div class="selector-header clickable">
    <button class="secondary-button" (click)="addRoom()">
      + {{ langService.dictionary["room"][langService.lang] }}
    </button>
  </div>
  <div class="selector-wrapper clickable">
    <div class="room-panel-wrapper">
      <booking-room-card
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        [room]="room"
        [isEditing]="true"
      ></booking-room-card>
    </div>
  </div>
  <div class="button-wrapper clickable">
    <!-- <button class="secondary-button" (click)="closeBooking()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button> -->
  </div>
</div>
