<div class="self-check-in-wrapper" *ngIf="!isScannerOpen">
  <div class="hotel-name">
    {{ checkInService.checkInDetail.rooms[0].hotel_name }}
  </div>
  <div class="action-panel">
    <div class="arrival-wrapper">
      <ng-container *ngFor="let guest of guests">
        <arrival-card
          #guestForms
          [guest]="guest"
          [totalGuests]="guests.length"
          [roomId]="selectedRoom.room_id"
          (isValid)="checkValidations()"
          (addGuest)="addSingleGuest($event)"
          (openCheckInScanner)="openScanner()"
          *ngIf="selectedRoom.room_id === guest.room_id"
        ></arrival-card>
      </ng-container>
    </div>
  </div>
  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of rooms"
        class="room-panel"
        [ngClass]="{
          selected: room.room_id === selectedRoom.room_id
        }"
        (click)="selectRoom(room)"
      >
        <div class="room-name">
          <span>{{ room.room_name }}</span>
        </div>

        <div class="date-wrapper">
          <div class="date">
            <span>{{ room.arrival_date }}</span>
          </div>
          <span class="num-of-nights"
            >{{
              calendarService.calculateDaysDifference(
                room.arrival_date,
                room.departure_date
              )
            }}
            {{ langService.dictionary["night"][langService.lang] }}</span
          >

          <div class="date">
            <span>{{ room.departure_date }}</span>
          </div>
        </div>

        <div class="details">
          <div class="occupancy-wrapper">
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ room.adults }}</span>
              </div>
              <div>
                <i class="adult-icon"></i>
              </div>
            </div>
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ room.children }}</span>
              </div>
              <div>
                <i class="child-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-wrapper"></div>
</div>
<scanner
  class="scanner"
  *ngIf="isScannerOpen"
  [roomId]="selectedRoom?.room_id"
  [isCheckIn]="true"
  (closeScanner)="closeScanner()"
></scanner>
