<div class="delete-booking-dialog-wrapper">
  <p>
    {{ langService.dictionary["delete-booking-confirm"][langService.lang] }}
  </p>
  <div class="button-wrapper">
    <button class="secondary-button" (click)="cancel()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button class="delete-button" (click)="delete()">
      {{ langService.dictionary["delete"][langService.lang] }}
    </button>
  </div>
</div>
