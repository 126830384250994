<div
  class="room-panel"
  [ngClass]="{ extended: isEditing && isBookingModified() }"
>
  <div class="room-panel-header">
    <div class="status">
      <span>{{
        bookingService.sidePanelRooms[room.key]
          | bookingStatus : langService.lang
      }}</span>
    </div>

    <div class="room-name">
      <!-- <span
        *ngIf="
          !bookingService.sidePanelRooms[room.key].newRoom ||
          bookingService.sidePanelRooms[room.key].room_id !== 0
        "
        >{{ roomService.getRoomName(room.key) }}</span
      > -->
      <custom-select
        class="form-dropdown-select"
        (change)="selectRoom($event, room.key)"
        [options]="roomSelectOptions"
        [transparent]="true"
        [_value]="bookingService.sidePanelRooms[room.key].room_id"
      />
    </div>

    <div class="delete">
      <button type="button" (click)="deleteRoom()">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </div>

  <div class="left-col">
    <div class="date-wrapper">
      <date-picker
        name="inputStartDate"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].arrival_date"
        (change)="
          tableService.handleDatePickerV2(
            room.key,
            bookingService.sidePanelRooms[room.key].arrival_date,
            true
          );
          updateLocalPrice()
        "
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          bookingService.sidePanelRooms[room.key].arrived
        "
        [filter]="tableService.getBookedDayFilter"
        (clicked)="setRoomIdForCalendar(room.key)"
      ></date-picker>
      <div class="nights-wrapper">
        <button (click)="subtractNight()">-</button>
        <span class="num-of-nights"
          >{{
            calendarService.calculateDaysDifference(
              bookingService.sidePanelRooms[room.key].arrival_date,
              bookingService.sidePanelRooms[room.key].departure_date
            )
          }}
          {{ langService.dictionary["night"][langService.lang] }}</span
        >
        <button (click)="addNight()">+</button>
      </div>

      <date-picker
        name="inputDepartureDate"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].departure_date"
        (change)="
          tableService.handleDatePickerV2(
            room.key,
            bookingService.sidePanelRooms[room.key].departure_date,
            false
          );
          updateLocalPrice()
        "
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          bookingService.sidePanelRooms[room.key].departed
        "
        [filter]="tableService.getBookedDayFilter"
        (clicked)="setRoomIdForCalendar(room.key)"
      ></date-picker>
    </div>

    <div class="occupancy-wrapper">
      <div class="occupancy-block">
        <input
          [disabled]="
            room.key.split('_')[0] === 'newRoom' ||
            bookingService.sidePanelRooms[room.key].arrived
          "
          type="number"
          [(ngModel)]="bookingService.sidePanelRooms[room.key].adults"
          (ngModelChange)="setGrossPrice()"
        />
        <div>
          <i class="adult-icon"></i>
        </div>
      </div>
      <div class="occupancy-block">
        <input
          [disabled]="
            room.key.split('_')[0] === 'newRoom' ||
            bookingService.sidePanelRooms[room.key].arrived
          "
          type="number"
          [(ngModel)]="bookingService.sidePanelRooms[room.key].children"
        />
        <div>
          <i class="child-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="right-col">
    <div class="amount-block cost-row">
      <input
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          bookingService.sidePanelRooms[room.key].paid
        "
        class="amount"
        type="number"
        [(ngModel)]="price"
        (change)="setGrossPrice()"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>

    <div class="ifa-wrapper cost-row">
      <custom-checkbox
        type="checkbox"
        name="ifa"
        [label]="'+ IFA'"
        [_value]="!bookingService.sidePanelRooms[room.key].ifa_included"
        (userInput)="changeIfaIncluded()"
        *ngIf="hotelService?.selectedHotel?.flat_IFA"
      />
      <span *ngIf="!hotelService?.selectedHotel?.flat_IFA">IFA</span>
      <div class="ifa-amount-block">
        <input
          [disabled]="true"
          class="amount"
          type="number"
          [value]="
            flat_ifa
              ? ifa *
                calendarService.calculateDaysDifference(
                  bookingService.sidePanelRooms[room.key].arrival_date,
                  bookingService.sidePanelRooms[room.key].departure_date
                ) *
                bookingService.sidePanelRooms[room.key].adults
              : bookingService.sidePanelRooms[room.key].adults === 0
              ? 0
              : ((ifa / 100) *
                  bookingService.sidePanelRooms[room.key].gross_price) /
                (1 + ifa / 100 / 1.05) /
                1.05
          "
        />
        <input type="text" class="huf-label" value="HUF" disabled />
      </div>
    </div>

    <div
      class="pre-payment-wrapper cost-row"
      *ngIf="!isEditing && isPrepaymentEnabled"
    >
      <span>{{ langService.dictionary["prepayment"][langService.lang] }}</span>
      <input
        class="amount"
        [type]="'number'"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].prepayment"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>

    <div class="total-wrapper cost-row">
      <span>{{ langService.dictionary["total"][langService.lang] }}</span>
      <input
        class="amount"
        [type]="'number'"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].gross_price"
        [disabled]="true"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>
  </div>

  <div class="modify-wrapper">
    <button class="secondary-button" (click)="cancelModification()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="secondary-button"
      *ngIf="isEditing && !room?.value?.newRoom"
      (click)="editRoom()"
      [disabled]="room?.value?.departed"
    >
      {{ langService.dictionary["modify"][langService.lang] }}
    </button>
    <button
      class="secondary-button"
      *ngIf="isEditing && room?.value?.newRoom"
      (click)="addRoom()"
      [disabled]="room?.value?.departed"
    >
      {{ langService.dictionary["save"][langService.lang] }}
    </button>
  </div>
</div>
