import { Component } from '@angular/core';
import { LanguageService } from 'src/app/@services';

@Component({
  selector: 'new-version',
  templateUrl: './new-version.dialog.html',
  styleUrls: ['./new-version.dialog.scss'],
})
export class NewVersionDialog {
  constructor(public langService: LanguageService) {}
}
