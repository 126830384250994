import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Subscription, filter } from 'rxjs';
import { NewVersionDialog } from './@dialogs';
import { environment } from './@environments/environtment';
import {
  ConfigLoaderService,
  ConfigService,
  LanguageService,
  RouterService,
  UserService,
} from './@services';
import { AuthService } from './@services/auth.service';
import { FocusService } from './@services/focus.service';
import { HotelService } from './@services/hotel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public authService: AuthService,
    private router: Router,
    private focusService: FocusService,
    private hotelService: HotelService,
    private routerService: RouterService,
    private userService: UserService,
    private renderer: Renderer2,
    private configLoaderService: ConfigLoaderService<{ [key: string]: any }>,
    private configService: ConfigService,
    private languageService: LanguageService,
    private matDialog: MatDialog
  ) {
    this.userService.setTheme();

    if (Capacitor.isNativePlatform()) {
      this.configService.getVersion().then((version: string) => {
        App.getInfo().then((info: AppInfo) => {
          if (info.version !== version) this.matDialog.open(NewVersionDialog);
        });
      });
    }

    const isBuild = !window.location.hostname.includes('localhost');
    if (isBuild && environment.env === 'dev') {
      this.insertScript();
    }

    this.hotelService
      .getHotels()
      .then(() => {
        this.hotelService.selectHotel(this.hotelService.hotels[0]);
        this.authService.IsAuthenticated = true;
        if (this.router.url.split('?')[0] === '/login') {
          this.routerService.absoluteNavigation('home');
        }
        this.userService
          .getUser()
          .then(() => {
            this.languageService.lang =
              this.userService.user.language.toLowerCase();
          })
          .catch(() => {});
      })
      .catch(() => {
        if (this.router.url.split('?')[0] !== 'register') {
          this.routerService.absoluteNavigation('/login');
        }
      });

    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.focusService.selectedMenu = event.url.split('/')[2];
        this.focusService.selectedSubMenu = event.url.split('/')[3];
        this.routerSub.unsubscribe();
      });

    this.configLoaderService.config$.subscribe(() => {
      this.languageService.dictionary =
        this.configLoaderService.config['dictionary'];
      this.languageService.languages =
        this.configLoaderService.config['languages'];
    });
  }

  title = 'KisPortas';
  private routerSub: Subscription;

  private insertScript(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://umami.kisportas.hu/script.js';
    script.defer = true;
    script.setAttribute(
      'data-website-id',
      '5359e5db-0fe6-4025-9fbb-28820ed084a2'
    );
    script.onload = () => {
      console.log('Script loaded successfully');
    };

    script.onerror = () => {
      console.error('Failed to load the script');
    };
    this.renderer.appendChild(document.head, script);
  }
}
