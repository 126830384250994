<div class="admin-wrapper">
  <div class="sub-menu-wrapper">
    <div class="sub-menu">
      <a
        (click)="selectSubPage('hotel')"
        [ngClass]="{
          active: routerService.subMenu === 'hotel'
        }"
        >{{ langService.dictionary["accommodation"][langService.lang] }}</a
      >
      <a
        (click)="selectSubPage('rooms')"
        [ngClass]="{
          active: routerService.subMenu === 'rooms'
        }"
        >{{ langService.dictionary["rooms"][langService.lang] }}</a
      >
      <a
        (click)="selectSubPage('services')"
        [ngClass]="{
          active: routerService.subMenu === 'services'
        }"
        >{{ langService.dictionary["services"][langService.lang] }}</a
      >
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
