import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { paymentOptions, vatRateOptions } from 'src/app/@consts';
import { ChargeInterface } from 'src/app/@Interfaces';
import { ChargeService, LanguageService, RoomService } from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';

@Component({
  selector: 'charge-card',
  templateUrl: './charge-card.component.html',
  styleUrls: ['./charge-card.component.scss'],
})
export class ChargeCardComponent implements OnInit, OnDestroy {
  constructor(
    private chargeService: ChargeService,
    private roomService: RoomService,
    public langService: LanguageService
  ) {}

  @Input('charge') charge: ChargeInterface;
  @ViewChild('chargeForm') chargeForm: NgForm;
  @Output('refreshData') refreshData: EventEmitter<void> =
    new EventEmitter<void>();

  private originalCharge: ChargeInterface;
  public readonly paymentOptions: any = paymentOptions;
  public readonly vatRateOptions: any = vatRateOptions;
  public chargeTypes = ChargeTypes;

  ngOnInit(): void {
    this.originalCharge = structuredClone(this.charge);
  }

  public postCharge(charge: ChargeInterface): void {
    charge.room_id = this.roomService.selectedRoom.room_id;
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .postCharge(charge)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public payCharge(payingCharge: ChargeInterface): void {
    const requestBody = {
      charges: [
        {
          charge_id: payingCharge.charge_id,
          payment_method: payingCharge.payment_method,
          payment_date: payingCharge.payment_date,
        },
      ],
    };
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public editCharge(charge: ChargeInterface): void {
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .editCharge(charge)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public deleteCharge(charge: ChargeInterface): void {
    this.chargeService
      .deleteCharge(charge.charge_id)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public resetChargeForm(): void {
    this.charge = JSON.parse(JSON.stringify(this.originalCharge));
  }

  public isModified(): boolean {
    return JSON.stringify(this.charge) !== JSON.stringify(this.originalCharge);
  }

  public isNewCharge(chargeId: number): boolean {
    return !isNaN(chargeId);
  }

  ngOnDestroy(): void {}
}
