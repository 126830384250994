<div class="nav-bar-wrapper">
  <div class="left-col">
    <div class="nav-button" (click)="navigate('home')">
      <i class="home-icon"></i>
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'home'
        }"
        >{{ langService.dictionary["home"][langService.lang] }}</a
      >
    </div>
    <div class="nav-button" (click)="navigate('calendar')">
      <i class="calendar-icon"></i>
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'calendar'
        }"
        >{{ langService.dictionary["calendar"][langService.lang] }}</a
      >
    </div>
    <div class="nav-button" (click)="navigate('admin')">
      <i class="admin-icon"></i>
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'admin'
        }"
        >{{ langService.dictionary["admin"][langService.lang] }}</a
      >
    </div>
  </div>
  <div class="middle-col">
    <div class="hotel-select">
      <select #hotelSelect class="dropdown-select">
        <option
          *ngFor="let hotel of hotelService.hotels"
          [value]="hotel"
          (click)="hotelSelected(hotel)"
        >
          {{ hotel.name }}
        </option>
      </select>
      <span class="dropdown-chevron">&#8744;</span>
    </div>
  </div>
  <div class="right-col">
    <div *ngIf="isNative" class="nav-button" (click)="navigate('qr-scanner')">
      <i class="scan-icon"></i>
      <a>{{ langService.dictionary["scan"][langService.lang] }}</a>
    </div>
    <div class="nav-button" (click)="navigate('profil')">
      <i class="profile-icon"></i>
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'profil'
        }"
        >{{ langService.dictionary["profile"][langService.lang] }}</a
      >
    </div>
    <div class="nav-button" (click)="navigate('support')">
      <i class="help-icon"></i>
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'support'
        }"
        >{{ langService.dictionary["help"][langService.lang] }}</a
      >
      <!-- <mat-icon>help_outline</mat-icon> -->
    </div>
  </div>
</div>
