import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable()
export class ConfigLoaderService<T> {
  public config!: T;
  public config$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(private http: HttpClient) {}

  public initialize(path = 'assets/config.json') {
    const jsonFile = `${path}?t=${new Date().getTime()}`;
    return new Promise<void>((resolve, reject) => {
      lastValueFrom(this.http.get<T>(jsonFile))
        .then((response) => {
          this.config = response;
          this.config$.next();
          resolve();
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err, 'error in catch');
          reject(
            `Could not load file '${jsonFile}': ${JSON.stringify(err.error)}`
          );
        });
    });
  }
}
