import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';
import {
  countryOfResidenceOptions,
  documentTypeOptions,
  genderOptions,
  nationalityOptions,
  taxExemptionReasonOptions,
} from 'src/app/@consts';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import {
  BookingService,
  CalendarService,
  CheckInService,
  DateService,
  FocusService,
  GuestService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
} from 'src/app/@services';

@Component({
  selector: 'arrival-card',
  templateUrl: './arrival-card.component.html',
  styleUrls: ['./arrival-card.component.scss'],
})
export class ArrivalCardComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private cdr: ChangeDetectorRef,
    private checkInService: CheckInService,
    private dateAdapter: DateAdapter<Date>,
    private dateService: DateService,
    public guestService: GuestService,
    private hotelService: HotelService,
    private routerService: RouterService,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public focusService: FocusService,
    public roomService: RoomService,
    public langService: LanguageService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
    this.isMobile = Capacitor.isNativePlatform();
  }

  @Input() guest: GuestInterface;
  @Input() totalGuests: number;
  @Input() roomId: string | number;
  @ViewChild('guestForm', { static: false }) guestForm: NgForm;
  @ViewChild('last_name', { static: false }) asd: NgForm;
  @Output('isValid') isValid = new EventEmitter<void>();
  @Output('openCheckInScanner') openCheckInScanner = new EventEmitter<void>();
  @Output('addGuest') addGuestEmmiter = new EventEmitter<GuestInterface>();
  public isLoading: boolean;
  public needsScanning: boolean = true;
  public documentType: 'id' | 'passport' = 'id';
  public readonly genderOptions = genderOptions;
  public readonly documentTypeOptions = documentTypeOptions;
  public readonly countryOfResidenceOptions = countryOfResidenceOptions;
  public readonly nationalityOptions = nationalityOptions;
  public readonly taxExemptionReasonOptions = taxExemptionReasonOptions;

  private originalFormVal: string;
  private paramSub: Subscription;
  public qrData: any;

  public detailedView = false;

  public isMobile: boolean;

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      this.qrData = JSON.stringify({
        hotelId: this.hotelService.selectedHotelId,
        bookingId:
          params.booking ?? this.bookingService?.selectedBooking?.booking_id,
        roomId: this.roomId,
        totalGuests: this.totalGuests,
      });
    });
  }

  ngAfterViewInit(): void {
    this.guestForm.valueChanges.subscribe(() => {
      this.isValid.emit();
    });

    this.originalFormVal = JSON.stringify(this.guest);

    this.needsScanning =
      !this.guest.document_type_og && !this.guest.document_number;

    this.cdr.detectChanges();
  }

  public addGuest(): void {
    if (!this.guest.first_name_og) this.copyDataToOgFields();
    if (this.guest.document_type === 'U14') this.attachEmptyOgFields();
    this.addGuestEmmiter.emit(this.guest);
  }

  public editGuest(): void {
    this.guestService
      .editGuest(this.guest)
      .then((editedGuest: GuestInterface) => {
        this.guest = editedGuest;
        this.originalFormVal = JSON.stringify(editedGuest);
        this.guestForm.form.markAsPristine();
      })
      .catch(() => {});
  }

  public resetGuest(): void {
    this.guest = JSON.parse(this.originalFormVal);
    this.guestForm.form.markAsPristine();
  }

  public formatDate(charge: GuestInterface, date: Date): void {
    charge.birth_date = this.dateService.formatDate(date);
  }

  public skipScanning(): void {
    this.needsScanning = false;
  }

  public toggleDetailedView(): void {
    this.detailedView = !this.detailedView;
  }

  protected setTaxExempt(date: string): void {
    const isOver18 = this.dateService.isOver18(date);
    if (isOver18) return;
    this.guest.tax_exempt = !isOver18;
    this.guest.tax_exemption_reason = 'im1';
  }

  protected resetTaxReason(bool: Boolean): void {
    if (bool) return;
    this.guest.tax_exemption_reason = null;
  }
  protected openScanner(): void {
    if (this.checkInService.checkInId) {
      this.openCheckInScanner.emit();
    } else {
      this.routerService.absoluteNavigation('scanner');
    }
  }

  protected checkU14(): void {
    if (this.guest.document_type !== 'U14') return;
    this.guest.document_number = '-';
    this.guest.tax_exemption_reason = 'im1';
    this.guest.tax_exempt = true;
  }

  private attachEmptyOgFields(): void {
    this.guest.birth_date_og = null;
    this.guest.document_number_og = null;
    this.guest.document_type_og = null;
    this.guest.first_name_og = null;
    this.guest.gender_og = null;
    this.guest.last_name_og = null;
    this.guest.nationality_og = null;
  }

  private copyDataToOgFields(): void {
    this.guest.birth_date_og = this.guest.birth_date;
    this.guest.document_number_og = this.guest.document_number;
    this.guest.document_type_og = this.guest.document_type;
    this.guest.first_name_og = this.guest.first_name;
    this.guest.gender_og = this.guest.gender;
    this.guest.last_name_og = this.guest.last_name;
    this.guest.nationality_og = this.guest.nationality;
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
