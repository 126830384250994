<div class="service-add-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["add"][langService.lang] }}</h1>
  </div>
  <div class="form-wrapper">
    <form #serviceForm="ngForm">
      <custom-input
        [type]="'text'"
        id="name"
        name="name"
        [required]="true"
        [(ngModel)]="service.name"
        [label]="langService.dictionary['service-name'][langService.lang] + '*'"
        [maxlength]="32"
      />
      <custom-input
        [type]="'number'"
        id="price_huf"
        name="price_huf"
        [required]="true"
        [(ngModel)]="service.price_huf"
        [label]="
          langService.dictionary['gross-price'][langService.lang] + ' HUF*'
        "
      />
      <custom-select
        id="category"
        name="category"
        [required]="true"
        [(ngModel)]="service.category"
        [label]="langService.dictionary['category'][langService.lang] + '*'"
        [options]="serviceCategoryOptions"
      />
      <custom-select
        id="vat_rate"
        name="vat_rate"
        [required]="true"
        [(ngModel)]="service.vat_rate"
        [label]="langService.dictionary['vat'][langService.lang] + '*'"
        [options]="vatRateOptions"
      />
    </form>
  </div>
  <div class="button-wrapper">
    <button class="primary-button" type="submit" (click)="newService()">
      + {{ langService.dictionary["add"][langService.lang] }}
    </button>
  </div>
</div>
