<div class="booking-rooms-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header clickable">
    <button class="secondary-button" (click)="addRoom()">
      + {{ langService.dictionary["room"][langService.lang] }}
    </button>
  </div>
  <div class="action-wrapper clickable">
    <div class="group-date-wrapper">
      <date-picker
        name="group_arrival_date"
        [isDateString]="true"
        [label]="langService.dictionary['group-arrival-date'][langService.lang]"
        (change)="setGroupArrivalDate($event)"
        [(ngModel)]="groupArrivalDate"
        [filter]="tableService.groupDateFilter"
      ></date-picker>
      <date-picker
        name="group_departure_date"
        [isDateString]="true"
        [label]="
          langService.dictionary['group-departure-date'][langService.lang]
        "
        [(ngModel)]="groupDepartureDate"
        (change)="setGroupDepartureDate($event)"
      ></date-picker>
    </div>
    <div class="room-panel-wrapper">
      <booking-room-card
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        [room]="room"
      ></booking-room-card>
    </div>
  </div>

  <div class="action-footer clickable">
    <button class="secondary-button" (click)="closeBooking()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="primary-button"
      (click)="postBooking($event)"
      [disabled]="!tableService.validateBookings()"
    >
      <span *ngIf="!isLoading">{{
        langService.dictionary["confirm-booking"][langService.lang]
      }}</span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
