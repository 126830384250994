import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private userService: UserService) {}

  public dictionary: { [key: string]: any } = {};
  public lang: string = 'hu';
  public languages: { value: string; label: { [key: string]: string } }[] = [];

  public changeLang(): void {
    this.userService.setUserLang(this.lang).catch(() => {});
  }
}
