import { Component, EventEmitter, Output } from '@angular/core';
import { LanguageService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'booking-rooms-depart',
  templateUrl: './booking-rooms-depart.component.html',
  styleUrls: ['./booking-rooms-depart.component.scss'],
})
export class BookingRoomsDepartComponent {
  constructor(
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public langService: LanguageService
  ) {}

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  public roomsToDepart: string[] = [];
  public isLoading: boolean;

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public cancel(): void {
    this.closePanel.emit();
  }

  public selectToDepart(roomId: any): void {
    if (
      this.bookingService.sidePanelRooms[roomId].departed ||
      !this.bookingService.sidePanelRooms[roomId].paid ||
      !this.bookingService.sidePanelRooms[roomId].arrived
    ) {
      return;
    }

    const index = this.roomsToDepart.indexOf(roomId);

    if (index !== -1) {
      this.roomsToDepart.splice(index, 1);
    } else {
      this.roomsToDepart.push(roomId);
    }
  }

  public departSelectedRooms(): void {
    const roomIds = this.roomsToDepart.map((room) => Number(room));
    this.isLoading = true;
    this.bookingService
      .departRooms(
        this.bookingService.selectedBooking.booking_id,
        this.roomsToDepart
      )
      .then(() => {
        this.isLoading = false;
        this.bookingService.updateBookingRoomStatuses(
          this.bookingService.selectedBooking.booking_id,
          roomIds,
          'departed',
          true
        );
        this.bookingService.setSelectedBookingsRooms();
        this.roomsToDepart = [];
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public selectAll(): void {
    const keys = Object.keys(this.bookingService.sidePanelRooms);
    for (const roomKey of keys) {
      if (
        this.bookingService.sidePanelRooms[roomKey].paid &&
        this.bookingService.sidePanelRooms[roomKey].arrived
      ) {
        this.selectToDepart(roomKey);
      }
    }
  }
}
