<div class="arrival-card-wrapper">
  <div *ngIf="needsScanning" class="scan-overlay">
    <h2 *ngIf="!isMobile">
      {{ langService.dictionary["scan-instructions"][langService.lang] }}
    </h2>
    <qrcode
      *ngIf="!isMobile"
      [qrdata]="qrData"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
    <button *ngIf="isMobile" class="primary-button" (click)="openScanner()">
      Scan
    </button>
    <p>{{ langService.dictionary["or"][langService.lang] }}</p>
    <button class="primary-button" (click)="skipScanning()">
      {{ langService.dictionary["manual-scan"][langService.lang] }}
    </button>
  </div>
  <form (ngSubmit)="addGuest()" #guestForm="ngForm">
    <div class="guest-card">
      <div class="row-wrapper">
        <custom-input
          #last_name
          [type]="'text'"
          [required]="true"
          [(ngModel)]="guest.last_name"
          name="last_name"
          [maxlength]="32"
          [label]="langService.dictionary['last-name'][langService.lang] + '*'"
          [highlighted]="true"
        />
        <custom-input
          [type]="'text'"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.first_name"
          name="first_name"
          [maxlength]="32"
          [label]="langService.dictionary['first-name'][langService.lang] + '*'"
        />
        <custom-select
          type="dropdown"
          class="form-dropdown-select"
          name="document_type"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.document_type"
          (ngModelChange)="checkU14()"
          [label]="
            langService.dictionary['identification-document'][
              langService.lang
            ] + '*'
          "
          [options]="documentTypeOptions"
        />
        <custom-input
          [type]="'text'"
          [(ngModel)]="guest.document_number"
          [disabled]="guest.document_type === 'U14'"
          name="document_number"
          [maxlength]="16"
          [required]="true"
          [highlighted]="true"
          [label]="
            langService.dictionary['identification-number'][langService.lang] +
            '*'
          "
        ></custom-input>
      </div>

      <div class="row-wrapper">
        <custom-select
          type="dropdown"
          class="form-dropdown-select"
          name="country_of_residence"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.country_of_residence"
          [label]="
            langService.dictionary['country-of-residence'][langService.lang] +
            '*'
          "
          [options]="countryOfResidenceOptions"
        />
        <custom-input
          [type]="'text'"
          [(ngModel)]="guest.postcode_of_residence"
          name="postcode_of_residence"
          [maxlength]="10"
          [required]="true"
          [highlighted]="true"
          [label]="
            langService.dictionary['postcode-of-residence'][langService.lang] +
            '*'
          "
        />
        <custom-select
          type="dropdown"
          class="form-dropdown-select"
          name="nationality"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.nationality"
          [label]="
            langService.dictionary['nationality'][langService.lang] + '*'
          "
          [options]="nationalityOptions"
        />

        <custom-select
          type="dropdown"
          class="form-dropdown-select"
          name="gender"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.gender"
          [label]="langService.dictionary['gender'][langService.lang] + '*'"
          [options]="genderOptions"
        />
      </div>
      <div class="row-wrapper">
        <custom-input
          [type]="'text'"
          [required]="true"
          [highlighted]="true"
          [(ngModel)]="guest.birth_place"
          name="birth_place"
          [maxlength]="32"
          [label]="langService.dictionary['birthplace'][langService.lang] + '*'"
        />
        <date-picker
          [(ngModel)]="guest.birth_date"
          (ngModelChange)="setTaxExempt($event)"
          name="birth_date"
          [isDateString]="true"
          [required]="true"
          [highlighted]="true"
          [label]="langService.dictionary['birthdate'][langService.lang] + '*'"
        />
        <custom-checkbox
          *ngIf="!guestService.isSelfCheckIn"
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="guest.tax_exempt"
          name="tax_exempt"
          [label]="langService.dictionary['tax-exempt'][langService.lang]"
          (ngModelChange)="resetTaxReason($event)"
        />
        <custom-select
          type="dropdown"
          class="form-dropdown-select"
          name="tax_exemption_reason"
          [required]="true"
          [highlighted]="true"
          *ngIf="guest.tax_exempt && !guestService.isSelfCheckIn"
          [label]="
            langService.dictionary['tax-exemption-reason'][langService.lang] +
            '*'
          "
          [(ngModel)]="guest.tax_exemption_reason"
          [options]="taxExemptionReasonOptions"
        />
        <div *ngIf="!guest.tax_exempt" class="filler"></div>
      </div>

      <div class="row-wrapper" *ngIf="detailedView">
        <custom-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.birth_last_name"
          name="birth_last_name"
          [maxlength]="32"
          [label]="langService.dictionary['birth-last-name'][langService.lang]"
        />
        <custom-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.birth_first_name"
          name="birth_first_name"
          [maxlength]="32"
          [label]="langService.dictionary['birth-first-name'][langService.lang]"
        />
        <custom-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.mothers_last_name"
          name="mothers_last_name"
          [maxlength]="32"
          [label]="
            langService.dictionary['mothers-last-name'][langService.lang]
          "
        />
        <custom-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.mothers_first_name"
          name="mothers_first_name"
          [maxlength]="32"
          [label]="
            langService.dictionary['mothers-first-name'][langService.lang]
          "
        />
      </div>

      <div class="row-wrapper" *ngIf="detailedView">
        <custom-input
          [type]="'text'"
          [(ngModel)]="guest.visa_number"
          name="visa_number"
          [label]="langService.dictionary['visa-number'][langService.lang]"
          [maxlength]="16"
        />
        <custom-input
          [type]="'text'"
          [(ngModel)]="guest.visa_arrival_place"
          name="visa_arrival_place"
          [label]="
            langService.dictionary['visa-arrival-place'][langService.lang]
          "
          [maxlength]="32"
        />
        <date-picker
          [(ngModel)]="guest.visa_arrival_date"
          name="visa_arrival_date"
          [isDateString]="true"
          [label]="
            langService.dictionary['visa-arrival-date'][langService.lang]
          "
        />
      </div>
      <div class="extra-button-wrapper">
        <button (click)="toggleDetailedView()" type="button">
          <span *ngIf="detailedView"> &#8963;</span
          >{{ langService.dictionary["extra-details"][langService.lang] }}
          <span *ngIf="!detailedView"> &#8964;</span>
        </button>
      </div>
      <div class="button-wrapper" *ngIf="!roomId">
        <button
          class="secondary-button"
          (click)="resetGuest()"
          type="button"
          *ngIf="guestForm.dirty && guest.guest_id"
        >
          {{ langService.dictionary["cancel"][langService.lang] }}
        </button>
        <button
          type="submit"
          *ngIf="!guest.guest_id"
          [disabled]="guestForm.invalid"
        >
          {{ langService.dictionary["save"][langService.lang] }}
        </button>
        <button
          type="submit"
          *ngIf="guest.guest_id && !guestForm.dirty"
          disabled
        >
          {{ langService.dictionary["checked-in"][langService.lang] }}
        </button>
        <button
          class="secondary-button"
          (click)="editGuest()"
          type="button"
          [disabled]="guestForm.invalid"
          *ngIf="guestForm.dirty && guest.guest_id"
        >
          {{ langService.dictionary["modify"][langService.lang] }}
        </button>
      </div>
      <div class="button-wrapper" *ngIf="roomId">
        <button
          type="submit"
          *ngIf="!guest.guest_id"
          [disabled]="guestForm.invalid"
        >
          {{ langService.dictionary["save"][langService.lang] }}
        </button>
        <button type="submit" *ngIf="guest.guest_id" disabled>
          {{ langService.dictionary["done"][langService.lang] }}
        </button>
      </div>
    </div>
  </form>
</div>
