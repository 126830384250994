<div class="scanner-wrapper">
  <div class="buttons-wrapper">
    <button
      class="camera-button secondary-button"
      (click)="switchNextVideo()"
      *ngIf="videoDevices.length > 1"
    >
      <mat-icon>camera_enhance</mat-icon>
    </button>
    <button class="doc-type-button secondary-button" (click)="switchIdType()">
      {{ documentType | documentType : langService.lang }}
    </button>
  </div>
  <div *ngIf="documentType === 'id'" class="tooltip">
    {{ langService.dictionary["scanner-read-id"][langService.lang] }}
  </div>
  <div *ngIf="documentType == 'passport'" class="tooltip">
    {{ langService.dictionary["scanner-read-passport"][langService.lang] }}
  </div>
  <div class="progress" *ngIf="guestService.readQrCode">
    <span class="scanned"
      >{{ peopleScanned }}/{{ guestService.readQrCode?.totalGuests }}</span
    >
  </div>
  <div class="progress" *ngIf="!guestService.readQrCode">
    <span class="scanned"
      >{{ peopleScanned }}/{{ guestService.totalGuestsToScan }}</span
    >
  </div>

  <canvas #canvas></canvas>

  <div
    class="image-wrapper"
    [style.backgroundImage]="'url(' + divBackgroundSource + ')'"
    *ngIf="divBackgroundSource"
  ></div>

  <ngx-scanner-text
    #scanner="scanner"
    [configs]="scannerConfig"
    (event)="onImageRead($event)"
  ></ngx-scanner-text>

  <button (click)="captureFrame()" class="capture-button primary-button">
    Scan
  </button>
  <div class="loading-screen" *ngIf="isLoading">
    <div class="scanner-animation"></div>
  </div>
  <div
    class="scans-finished"
    *ngIf="
      peopleScanned === guestService.readQrCode?.totalGuests ||
      peopleScanned === guestService.totalGuestsToScan
    "
  >
    <div class="scan-feedback" *ngIf="guestService.readQrCode">
      <h1>{{ langService.dictionary["scanner-done"][langService.lang] }}</h1>
      <p>
        {{ langService.dictionary["scanner-back-to-site"][langService.lang] }}
      </p>

      <button class="secondary-button" (click)="navToQrScanner()">
        {{ langService.dictionary["scan-new-qr"][langService.lang] }}
      </button>
      <span>{{ langService.dictionary["or"][langService.lang] }}</span>
      <button class="close-button" (click)="backToCalendar()">
        {{ langService.dictionary["close"][langService.lang] }}
      </button>
    </div>
    <div class="scan-feedback" *ngIf="!guestService.readQrCode">
      <h1>{{ langService.dictionary["scanner-done"][langService.lang] }}</h1>
      <button class="close-button" (click)="backToBooking()">
        {{ langService.dictionary["close"][langService.lang] }}
      </button>
    </div>
  </div>
</div>
