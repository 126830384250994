import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  HotelService,
  LanguageService,
  RouterService,
} from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'booking-edit-rooms',
  templateUrl: './booking-edit-rooms.component.html',
  styleUrls: ['./booking-edit-rooms.component.scss'],
})
export class BookingEditRoomsComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public tableService: TableService,
    public dateService: DateService,
    private routerService: RouterService,
    private hotelService: HotelService,
    public langService: LanguageService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  public _isEditing: boolean;
  public _sidePanelType: SidePanelTypeEnum;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  private counter: number = 0;

  public bookingGroup: any = new FormGroup({
    channel: new FormControl(null, Validators.required),
    contact_email: new FormControl(),
    contact_name: new FormControl(),
    contact_phone: new FormControl(),
    external_id: new FormControl(),
    group_booking: new FormControl(false),
    language: new FormControl(),
    nationality: new FormControl(),
    source: new FormControl(),
    work_related: new FormControl(false),
  });

  ngOnInit(): void {
    if (!this.bookingService.selectedBooking) return;
    this.tableService.loadRoomBookings(
      this.bookingService.selectedBooking.booking_id
    );
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public closeBooking(): void {
    this.routerService.absoluteNavigationNoParamPreserve('calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    //timeout for animation to finish
    setTimeout(() => {
      this.bookingChanel = null;

      this.calendarService.removeAvailableDayClasses();
      this.selectedLanguage = null;
      this.bookingGroup = new FormGroup({
        channel: new FormControl(null, Validators.required),
        contact_email: new FormControl(),
        contact_name: new FormControl(),
        contact_phone: new FormControl(),
        external_id: new FormControl(),
        group_booking: new FormControl(false),
        language: new FormControl(),
        nationality: new FormControl(),
        source: new FormControl(),
        work_related: new FormControl(false),
      });
    }, 1000);
  }

  public editBookings(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingRoom(
        this.bookingService.selectedBooking.booking_id,
        this.bookingService.sidePanelRooms
      )
      .then(async () => {
        this.isLoading = false;
        await this.bookingService.getBookings();
        this.bookingService.sidePanelRooms =
          this.bookingService.setSelectedBookingsRooms();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public addRoom(): void {
    this.tableService.addRoom(this.counter);
    this.counter++;
  }

  public getSelectableRoomIds(roomId: string): string[] {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    const bookedRoomIds = Object.keys(this.bookingService.sidePanelRooms);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    );
    if (roomId.split('_')[0] !== 'newRoom') {
      roomIdsToLoop.push(roomId);
    }
    return roomIdsToLoop;
  }

  ngOnDestroy(): void {
    this.bookingService.removeTempRooms();
  }
}
