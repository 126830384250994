import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFilterFn } from '@angular/material/datepicker';
import { DateService } from 'src/app/@services';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  constructor(private dateService: DateService) {}
  @Input() _value: any;
  @Input() isDateString: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() label: string;
  @Input() filter: DateFilterFn<any>;
  @Input() highlighted: boolean;
  @Output() clicked = new EventEmitter<void>();
  @Output() change = new EventEmitter<string>();

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onInput(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
  }

  public formatDate(date: Date): void {
    if (!date) {
      this._value = null;
      return;
    }
    this._value = this.dateService.formatDate(date);
    this.sendChange();
  }

  public sendChange(): void {
    this.onChange(this._value);
    this.change.emit(this._value);
    this.onTouched();
  }

  public emitClick(): void {
    this.clicked.emit();
  }
}
