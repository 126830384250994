import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import {
  CheckInDetail,
  GuestInterface,
  IResponse,
  ScanInterface,
} from '../@Interfaces';

@Injectable({
  providedIn: 'root',
})
export class CheckInService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  public checkInId: string;
  public checkInDetail: CheckInDetail;

  public async getCheckInDetails(id: string): Promise<CheckInDetail> {
    return new Promise<CheckInDetail>((resolve, reject) => {
      this.http
        .get<IResponse<CheckInDetail>>(
          `${this.configService.server}api/anonym?uuid=${id}`
        )
        .subscribe({
          next: (res: IResponse<CheckInDetail>) => {
            this.checkInDetail = res.data;
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendCheckInScan(
    room_id: number,
    scan: ScanInterface
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/anonym/${this.checkInId}/room/${room_id}`,
          scan
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async saveCheckInGuest(
    id: string,
    guest: GuestInterface,
    roomId: number
  ): Promise<void> {
    const body = structuredClone(guest);
    delete body.guest_id;
    delete body.room_id;
    delete body.guest_arrival_id;
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse>(`${this.configService.server}api/anonym/${id}`, {
          room_id: roomId,
          guests: [body],
        })
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
