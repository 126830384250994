import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { CameraPreview } from 'capacitor-plugin-camera';
import * as MRZ from 'mrz';
import { Configs, NgxScannerTextComponent, Page } from 'ngx-scanner-text';
import { ErrorDialog } from 'src/app/@dialogs';
import {
  CheckInService,
  GuestService,
  LanguageService,
  RouterService,
  ScannerService,
} from 'src/app/@services';

@Component({
  selector: 'scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private cdr: ChangeDetectorRef,
    public guestService: GuestService,
    private scannerService: ScannerService,
    private routerService: RouterService,
    private renderer: Renderer2,
    private matDialog: MatDialog,
    private checkInService: CheckInService,
    public langService: LanguageService
  ) {}

  @Input() isCheckIn: boolean = false;
  @Input() roomId: number;
  @Output('closeScanner') closeScanner = new EventEmitter<void>();
  @ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('scanner', { static: false })
  scanner: NgxScannerTextComponent;
  public documentType: 'id' | 'passport' = 'id';

  private videoIndex: number = 0;
  private readonly acceptedLengths: number[] = [9, 30, 36, 44];

  protected isLoading: boolean;
  protected peopleScanned: number = 0;
  public videoDevices: string[] = [];
  public snapshot: string;
  public divBackgroundSource: string;
  public scannerConfig: Configs = {
    src: '',
    languages: ['eng'],
    color: 'red',
    isAuto: true,
    isImage: false,
  };

  ngOnInit(): void {
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');

    this.renderer.addClass(htmlElement, 'transparent-mode');
    this.renderer.addClass(bodyElement, 'transparent-mode');
  }

  async ngAfterViewInit(): Promise<void> {
    if (!Capacitor.isNativePlatform()) return;

    await this.checkPermission();

    CameraPreview.initialize().then(async () => {
      await CameraPreview.startCamera();
      await this.getVideoDevices();
      await this.calculateCameraROI();
      await CameraPreview.setZoom({ factor: 1 });

      this.drawOverlay();
    });
  }

  public async captureFrame(): Promise<void> {
    this.isLoading = true;
    this.snapshot = `data:image/png;base64,${
      (await CameraPreview.takeSnapshot({})).base64
    }`;
    this.scannerConfig.src = this.snapshot;
    this.divBackgroundSource = this.convertBase64ToBlobUrl(this.snapshot);

    this.onScanOCR(this.scanner);
  }

  public onImageRead(scan: Page): void {
    const mrz = this.getLastThreeLines(scan.text);
    try {
      const scanFields = MRZ.parse(mrz, { autocorrect: true }).fields;
      const guestDetails = {
        birth_date: scanFields.birthDate,
        document_number: scanFields.documentNumber,
        document_type: this.documentType === 'id' ? 'I' : 'P',
        first_name:
          this.postProcessString(scanFields.firstName) ?? scanFields.lastName,
        last_name:
          this.postProcessString(scanFields.lastName) ?? scanFields.firstName,
        gender: scanFields.sex,
        nationality: scanFields.nationality,
      };
      if (this.isCheckIn) {
        this.checkInService
          .sendCheckInScan(this.roomId, guestDetails)
          .then(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            this.peopleScanned++;
            console.log('person scanned ', this.peopleScanned);
          })
          .catch(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            console.log('send scan failed');
            this.matDialog.open(ErrorDialog, {
              data: 'Sikertelen beolvasás, kérem próbálja meg újra',
            });
          });
      } else {
        this.guestService
          .sendScan(this.guestService.readQrCode, guestDetails)
          .then(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            this.peopleScanned++;
            console.log('person scanned ', this.peopleScanned);
          })
          .catch(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            console.log('send scan failed');
            this.matDialog.open(ErrorDialog, {
              data: 'Sikertelen beolvasás, kérem próbálja meg újra',
            });
          });
      }
    } catch (error) {
      this.isLoading = false;
      this.snapshot = null;
      this.divBackgroundSource = null;
      console.log('mrz err');
      this.matDialog.open(ErrorDialog, {
        data: 'Sikertelen beolvasás, kérem próbálja meg újra',
      });
      // TODO: Error snackbar
    }

    this.cdr.detectChanges();
  }

  // This tries to get rid of faulty scans
  private postProcessString(str: string): string {
    let processedString = str;
    const match = processedString.match(
      /(  |C{3,}|K{3,}|L{3,}| K | L | C | S| KK| CK| K| C| S| L)/
    );
    if (match) {
      processedString = processedString.slice(0, match.index);
    }

    return processedString;
  }

  private drawOverlay(): void {
    const overlayCanvas = this.canvas.nativeElement;
    const overlayContext = overlayCanvas.getContext('2d');
    overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

    overlayCanvas.width = this.canvas.nativeElement.clientWidth;
    overlayCanvas.height = this.canvas.nativeElement.clientHeight;

    const roiWidth = overlayCanvas.width * 0.9;
    const roiHeight =
      this.documentType === 'id'
        ? (roiWidth / 321) * 204
        : (roiWidth / 295.5) * 207.8;

    const roiX = (this.canvas.nativeElement.clientWidth - roiWidth) / 2;
    const roiY = (this.canvas.nativeElement.clientHeight - roiHeight) / 2;
    console.log(
      'overlay roi xy width height ',
      roiX,
      roiY,
      roiWidth,
      roiHeight
    );

    overlayContext.strokeStyle = 'red';
    overlayContext.lineWidth = 2;
    overlayContext.strokeRect(roiX, roiY, roiWidth, roiHeight);
  }

  private async calculateCameraROI(): Promise<{
    top: number;
    left: number;
    right: number;
    bottom: number;
  }> {
    // Get camera resolution
    const cameraResolution = await CameraPreview.getResolution();
    const cameraWidth = Number(cameraResolution.resolution.split('x')[1]);
    const cameraHeight = Number(cameraResolution.resolution.split('x')[0]);
    console.log('Camera width x height:', cameraWidth, cameraHeight);

    // Canvas dimensions
    const canvasWidth = this.canvas.nativeElement.clientWidth;
    const canvasHeight = this.canvas.nativeElement.clientHeight;
    console.log('Canvas width x height:', canvasWidth, canvasHeight);

    // Calculate scaling factors
    const scaleX = cameraWidth / canvasWidth;
    const scaleY = cameraHeight / canvasHeight;
    console.log('Scaling factors (X, Y):', scaleX, scaleY);

    // Calculate ROI on the canvas
    const roiWidth = canvasWidth * 0.9;
    const aspectRatio = this.documentType === 'id' ? 321 / 204 : 295.5 / 207.8;
    const roiHeight = roiWidth / aspectRatio;

    const roiX = (canvasWidth - roiWidth) / 2;
    const roiY = (canvasHeight - roiHeight) / 2;

    console.log(
      'Canvas ROI (x, y, width, height):',
      roiX,
      roiY,
      roiWidth,
      roiHeight
    );

    // Map the ROI to the camera coordinate system
    const cameraROI = {
      left: roiX * scaleX,
      top: roiY * scaleY,
      right: (roiX + roiWidth) * scaleX,
      bottom: (roiY + roiHeight) * scaleY,
    };

    console.log('Mapped Camera ROI (top, left, right, bottom):', cameraROI);

    // Set the camera's cropping region
    await CameraPreview.setScanRegion({
      region: {
        top: cameraROI.top,
        left: cameraROI.left,
        right: cameraROI.right,
        bottom: cameraROI.bottom,
        measuredByPercentage: 0, // Absolute values
      },
    });

    return cameraROI;
  }

  private async getVideoDevices(): Promise<void> {
    this.videoDevices = (await CameraPreview.getAllCameras()).cameras;
  }

  public async switchNextVideo(): Promise<void> {
    if (this.videoDevices.length < 2) return;

    this.videoIndex =
      this.videoDevices.length - 1 !== this.videoIndex
        ? this.videoIndex + 1
        : 0;

    CameraPreview.selectCamera({
      cameraID: this.videoDevices[this.videoIndex],
    });

    const cameraResolution = await CameraPreview.getResolution();

    const cameraWidth = Number(cameraResolution.resolution.split('x')[1]); // Native camera width
    const cameraHeight = Number(cameraResolution.resolution.split('x')[0]); // Native camera height

    CameraPreview.setFocus({ x: cameraWidth / 2, y: cameraHeight / 2 });
  }

  public navToQrScanner(): void {
    this.routerService.absoluteNavigation('qr-scanner');
  }

  public backToBooking(): void {
    if (this.isCheckIn) {
      this.closeScanner.emit();
    } else {
      this.routerService.absoluteNavigation('calendar/arrival');
    }
  }

  public backToCalendar(): void {
    this.routerService.absoluteNavigation('calendar');
  }

  private onScanOCR(scanner: NgxScannerTextComponent): void {
    try {
      scanner.scanOCR(this.scannerConfig);
    } catch (error) {
      console.log('scanner error ', error);
    }
  }

  private async checkPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Capacitor.isNativePlatform()) resolve();
      const permission = await Camera.checkPermissions().catch(() => {
        return { camera: 'denied' };
      });
      console.log('permission ', permission);

      if (permission.camera !== 'denied') {
        resolve();
      } else {
        Camera.requestPermissions();
        resolve();
      }
    });
  }

  private getLastThreeLines(text: string): string {
    if (!text) return '';
    // Split the text by line breaks (supporting both Windows and Unix line endings)
    let lines = text.split(/\r?\n/);
    lines = lines.filter((line) => line !== '' || line.length > 5);

    // Get the last 3 lines
    let lastThreeLines =
      this.documentType === 'id' ? lines.slice(-3) : lines.slice(-2);
    lastThreeLines = this.adjustLinesToClosestNumber(
      lastThreeLines,
      this.acceptedLengths
    );

    // Join the lines back with line breaks
    return lastThreeLines.join('\n');
  }

  private adjustLinesToClosestNumber(
    lines: string[],
    numbers: number[]
  ): string[] {
    const firstLine = lines[0];

    const closestNumber = this.documentType === 'id' ? 30 : 44;

    const adjustedLines = lines.map((line) => {
      const lineLength = line.length;
      const difference = closestNumber - lineLength;

      if (difference > 0) {
        return line.padEnd(closestNumber, '<');
      } else if (difference < 0) {
        return line.slice(0, closestNumber);
      } else {
        return line;
      }
    });

    return adjustedLines;
  }

  public switchIdType(): void {
    this.documentType = this.documentType === 'id' ? 'passport' : 'id';
    this.drawOverlay();
  }

  private convertBase64ToBlobUrl(base64: string): string {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uintArray], { type: 'image/png' });
    return URL.createObjectURL(blob);
  }

  ngOnDestroy(): void {
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');
    this.renderer.removeClass(htmlElement, 'transparent-mode');
    this.renderer.removeClass(bodyElement, 'transparent-mode');
    this.guestService.totalGuestsToScan = null;
    this.guestService.readQrCode = null;
    CameraPreview.stopCamera();
  }
}
