import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../@environments/environtment';
import {
  HotelInterface,
  hotelOOOInterface,
  prepaymentReq,
} from '../@Interfaces/hotel.interface';
import { ConfigService } from './config.service';
import { DateService } from './date.service';
import { RouterService } from './router.service';
import { IResponse } from '../@Interfaces';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private routerService: RouterService,
    private configService: ConfigService
  ) {}

  public selectedHotel: HotelInterface;
  public userName: string = '';
  public selectedHotelId: number = 1;
  public selectedHotelRoomLimit: number = 5;
  public hotels: HotelInterface[];
  public isAutoConclude: boolean = false;
  public isDelayedConclude: boolean = false;
  public isAutoArrive: boolean = false;
  public isAutoDepart: boolean = false;
  public localPrepayment: prepaymentReq = {
    enabled: false,
    prepayment_type: null,
    prepayment_flat: 0,
    prepayment_percent: 0,
  };
  public hotelOOODates: hotelOOOInterface[] = [];

  public selectHotel(hotel: HotelInterface): void {
    this.selectedHotel = hotel;
    this.selectedHotelId = hotel.hotel_id;
    this.selectedHotelRoomLimit = hotel.room_limit;
    this.isAutoConclude = hotel.auto_conclude;
    this.isDelayedConclude = hotel.delayed_conclude;
    this.isAutoArrive = hotel.auto_arrive;
    this.isAutoDepart = hotel.auto_depart;
    this.localPrepayment.enabled = hotel.prepayment_enabled;
    this.localPrepayment.prepayment_flat = hotel.prepayment_flat;
    this.localPrepayment.prepayment_percent = hotel.prepayment_percent;
    this.localPrepayment.prepayment_type = hotel.prepayment_type;
    this.userName = hotel.user_name;
    this.routerService.addQueryParams({ hotel: hotel.hotel_id });
    this.getHotelOOO();
  }

  public updateLocalPrepayment(): void {
    this.selectedHotel.prepayment_enabled = this.localPrepayment.enabled;
    this.selectedHotel.prepayment_flat = this.localPrepayment.prepayment_flat;
    this.selectedHotel.prepayment_percent =
      this.localPrepayment.prepayment_percent;
    this.selectedHotel.prepayment_type = this.localPrepayment.prepayment_type;
    this.hotels.map((hotel) => {
      if (hotel.hotel_id !== this.selectedHotel.hotel_id) return;
      hotel.prepayment_enabled = this.localPrepayment.enabled;
      hotel.prepayment_flat = this.localPrepayment.prepayment_flat;
      hotel.prepayment_percent = this.localPrepayment.prepayment_percent;
      hotel.prepayment_type = this.localPrepayment.prepayment_type;
    });
  }

  public getHotels(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<HotelInterface[]>>(
          `${this.configService.server}api/hotels`
        )
        .subscribe({
          next: (res: IResponse<HotelInterface[]>) => {
            const hotels = res.data;

            if (!hotels) {
              reject();
            }

            this.hotels = hotels;
            this.selectHotel(hotels[0]);

            if (
              this.selectedHotel !== undefined &&
              this.selectedHotel !== null
            ) {
              this.hotels.forEach((hotel: HotelInterface) => {
                hotel.last_concluded_day = this.dateService.addDayToStringDate(
                  hotel.last_concluded_day,
                  1
                );
              });
            }
            resolve(true);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public concludeDay(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<{ success: boolean; concluded_day: string }>>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/conclude-day`,
          {
            date: this.selectedHotel.last_concluded_day,
          }
        )
        .subscribe({
          next: (
            response: IResponse<{ success: boolean; concluded_day: string }>
          ) => {
            this.selectedHotel.last_concluded_day =
              this.dateService.addDayToStringDate(
                response.data.concluded_day,
                1
              );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async autoConclude(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<IResponse<{ success: boolean; concluded_day: string }>>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/auto-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<IResponse<{ success: boolean; concluded_day: string }>>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/auto-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }
  public async delayedConclude(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<IResponse<{ success: boolean; concluded_day: string }>>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/delayed-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<IResponse<{ success: boolean; concluded_day: string }>>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/delayed-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }
  public async autoArrive(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<IResponse<{ success: boolean; concluded_day: string }>>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-arrive`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<IResponse<{ success: boolean; concluded_day: string }>>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-arrive`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }
  public async autoDepart(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<IResponse<{ success: boolean; concluded_day: string }>>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-depart`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<IResponse<{ success: boolean; concluded_day: string }>>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-depart`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }

  public async addCommunicationLanguage(lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/languages/${lang}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.languages.push(lang);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async removeCommunicationLanguage(lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/languages/${lang}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.languages = this.selectedHotel.languages.filter(
              (item) => item !== lang
            );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addCountry(country: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/countries/${country}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.countries.push(country);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async removeCountry(country: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/countries/${country}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.countries = this.selectedHotel.countries.filter(
              (item) => item !== country
            );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async editPrepayment(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/prepayment`,
          this.localPrepayment
        )
        .subscribe({
          next: () => {
            this.updateLocalPrepayment();
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async getHotelOOO(): Promise<hotelOOOInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<hotelOOOInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/hotel-ooo`
        )
        .subscribe({
          next: (res: IResponse<hotelOOOInterface[]>) => {
            this.hotelOOODates = res.data;
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addHotelOOO(body: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse<hotelOOOInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/hotel-ooo`,
          body
        )
        .subscribe({
          next: (res: IResponse<hotelOOOInterface[]>) => {
            this.hotelOOODates = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async modifyHotelOOO(body: any, hotelOOOId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/hotel-ooo/${hotelOOOId}`,
          body
        )
        .subscribe({
          next: (hotelOOO: any) => {
            resolve(hotelOOO);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteHotelOOO(hotelOOOId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<IResponse<hotelOOOInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/hotel-ooo/${hotelOOOId}`
        )
        .subscribe({
          next: (res: IResponse<hotelOOOInterface[]>) => {
            this.hotelOOODates = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public isDayHotelOOO(date: Date): boolean {
    const searchDate = this.dateService.formatDate(date);
    const isDateOOO: boolean = this.hotelOOODates.some(
      (oos: any) =>
        new Date(oos.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(oos.end_date).getTime()
    );
    return isDateOOO;
  }
}
