import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';

@Component({
  selector: 'booking-delete-dialog',
  templateUrl: './booking-delete.dialog.html',
  styleUrls: ['./booking-delete.dialog.scss'],
})
export class BookingDeleteDialog {
  constructor(
    private matDialogRef: MatDialogRef<BookingDeleteDialog>,
    private bookingService: BookingService,
    private calendarService: CalendarService,
    public langService: LanguageService
  ) {}

  public async delete(): Promise<void> {
    await this.bookingService
      .deleteBooking(this.bookingService.selectedBooking.booking_id)
      .then(() => {
        this.calendarService.setCalendarDays();
        this.matDialogRef.close(true);
      })
      .catch(() => {
        //TODO do error handling here
      });
  }

  public cancel(): void {
    this.matDialogRef.close(false);
  }
}
