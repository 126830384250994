<form
  #chargeForm="ngForm"
  class="charge-wrapper"
  [ngClass]="{ success: charge.paid }"
  (ngSubmit)="charge.charge_id ? payCharge(charge) : postCharge(charge)"
>
  <div class="header-wrapper">
    <div class="charge-type">
      <i class="double-bed-icon" *ngIf="charge.charge_type === 'ROOM'"></i>
      <i class="tax-icon" *ngIf="charge.charge_type === 'IFA'"></i>
      <i
        *ngIf="charge.charge_type === 'PREPAYMENT'"
        class="prepayment-icon"
      ></i>
      <i *ngIf="charge.charge_type === 'FOOD'" class="food-icon"></i>
      <i *ngIf="charge.charge_type === 'DRINK'" class="drink-icon"></i>
      <i *ngIf="charge.charge_type === 'WELLNESS'" class="wellness-icon"></i>
      <i *ngIf="charge.charge_type === 'OTHER'" class="other-service-icon"></i>
    </div>
    <!-- TODO -->
    <div class="name-wrapper">
      <input
        type="text"
        name="name"
        [(ngModel)]="charge.name"
        required
        [maxlength]="32"
        [disabled]="
          charge.charge_type === chargeTypes.ifa ||
          charge.charge_type === chargeTypes.room ||
          charge.paid
        "
      />
    </div>
  </div>
  <div
    class="delete-wrapper"
    *ngIf="
      charge.charge_id &&
      !charge.paid &&
      charge.charge_type !== chargeTypes.ifa &&
      charge.charge_type !== chargeTypes.room
    "
  >
    <button type="button" (click)="deleteCharge(charge)">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>

  <div class="cost-wrapper">
    <custom-input
      class="qty"
      name="qty"
      [type]="'number'"
      [label]="langService.dictionary['qty'][langService.lang] + '*'"
      [required]="true"
      [(ngModel)]="charge.qty"
      [disabled]="charge.paid || charge.payment_method !== null"
    />
    <custom-input
      class="unit"
      name="unit"
      [type]="'string'"
      [label]="langService.dictionary['unit'][langService.lang] + '*'"
      [required]="true"
      [(ngModel)]="charge.unit"
      [disabled]="charge.paid || charge.payment_method !== null"
      [maxlength]="32"
    />
    <custom-select
      id="vat_rate"
      name="vat_rate"
      class="vat-rate"
      [required]="true"
      [(ngModel)]="charge.vat_rate"
      [options]="vatRateOptions"
      [label]="langService.dictionary['vat'][langService.lang] + '*'"
      [disabled]="charge.paid || charge.payment_method !== null"
    />
    <custom-input
      class="price"
      name="gross_price"
      [type]="'number'"
      [label]="langService.dictionary['gross-price'][langService.lang] + '*'"
      [required]="true"
      [(ngModel)]="charge.gross_price"
      [disabled]="charge.paid || charge.payment_method !== null"
    />
  </div>
  <div class="total-wrapper">
    <custom-input
      class="total-price"
      name="totalPrice"
      [type]="'number'"
      [label]="langService.dictionary['total'][langService.lang]"
      [required]="true"
      [disabled]="true"
      [ngModel]="charge.gross_price * charge.qty"
    />
  </div>
  <div class="comment-wrapper">
    <div
      class="form-textbox comment"
      [ngClass]="{
        focused: true
      }"
    >
      <textarea
        type="textarea"
        name="comment"
        [(ngModel)]="charge.comment"
        [disabled]="charge.paid || charge.payment_method !== null"
        maxlength="128"
      ></textarea>
      <span class="form-textbox-label">{{
        langService.dictionary["comment"][langService.lang]
      }}</span>
    </div>
  </div>

  <div class="payment-method-wrapper">
    <custom-select
      id="payment_method"
      *ngIf="charge.charge_id"
      [name]="'payment_method'"
      class="payment-method"
      [required]="isNewCharge(charge.charge_id)"
      [(ngModel)]="charge.payment_method"
      [disabled]="charge.paid || (isModified() && !charge.payment_method)"
      [options]="paymentOptions"
      [label]="langService.dictionary['payment-method'][langService.lang] + '*'"
    >
    </custom-select>
  </div>

  <div class="payment-date-wrapper">
    <date-picker
      *ngIf="charge.charge_id"
      [disabled]="charge.paid || (isModified() && !charge.payment_method)"
      [isDateString]="true"
      [(ngModel)]="charge.payment_date"
      name="payment_date"
      [required]="isNewCharge(charge.charge_id)"
      [label]="langService.dictionary['payment-date'][langService.lang] + '*'"
    ></date-picker>
  </div>

  <div class="modification-button-wrapper">
    <button
      class="secondary-button"
      *ngIf="!charge.charge_id"
      [disabled]="chargeForm.invalid"
      type="submit"
    >
      {{ langService.dictionary["add"][langService.lang] }}
    </button>
    <div
      class="button-wrapper"
      *ngIf="!charge.payment_method && isModified() && charge.charge_id"
    >
      <button
        class="secondary-button cancel"
        (click)="resetChargeForm()"
        type="button"
      >
        {{ langService.dictionary["cancel"][langService.lang] }}
      </button>
      <button
        class="secondary-button modify"
        (click)="editCharge(charge)"
        type="button"
      >
        {{ langService.dictionary["modify"][langService.lang] }}
      </button>
    </div>
  </div>
  <div class="payment-button-wrapper">
    <button
      class="primary-button"
      *ngIf="
        charge.charge_id &&
        !charge.paid &&
        (!isModified() || charge.payment_method)
      "
      type="submit"
      [disabled]="chargeForm.invalid"
    >
      {{ langService.dictionary["pay"][langService.lang] }}
    </button>
    <button
      class="primary-button success"
      *ngIf="charge.charge_id && charge.paid"
      type="submit"
      [disabled]="true"
    >
      {{ langService.dictionary["paid"][langService.lang] }}
    </button>
  </div>
</form>
