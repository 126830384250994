<div class="services-wrapper">
  <div
    class="main-content"
    [ngClass]="
      routerService.tertiaryMenu === serviceMenu.Overview ? 'overview-mode' : ''
    "
  >
    <div class="selection-wrapper">
      <div class="header">
        <services-overview></services-overview>
      </div>
    </div>
    <div class="action-wrapper">
      <div class="action-panel">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="button-wrapper">
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Overview)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Overview ? 'active' : ''
        "
      >
        {{ langService.dictionary["overview"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Add)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Add ? 'active' : ''
        "
      >
        {{ langService.dictionary["add"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Edit)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Edit ? 'active' : ''
        "
      >
        {{ langService.dictionary["modify"][langService.lang] }}
      </button>
    </div>
  </div>
</div>
