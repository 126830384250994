import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { serviceCategoryOptions, vatRateOptions } from 'src/app/@consts';
import { LanguageService, ServiceService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { ServiceMenu } from 'src/app/enums';

@Component({
  selector: 'services-edit',
  templateUrl: './services-edit.component.html',
  styleUrls: ['./services-edit.component.scss'],
})
export class ServicesEditComponent implements OnInit, OnDestroy {
  constructor(
    public hotelService: HotelService,
    public serviceService: ServiceService,
    public focusService: FocusService,
    public langService: LanguageService
  ) {}

  ngOnInit(): void {}
  public form: FormGroup;

  public readonly vatRateOptions = vatRateOptions;
  public readonly serviceCategoryOptions = serviceCategoryOptions;

  public selectedMenu: string = ServiceMenu.Overview;
  public serviceMenu = ServiceMenu;

  private queryParamSub: Subscription;

  public deselectService(): void {
    this.serviceService.selectedService = null;
  }

  public editService(): void {
    const serviceId = this.serviceService.selectedService.service_id;
    this.serviceService
      .editService(this.serviceService.selectedService, serviceId.toString())
      .then(() => {
        /* this.serviceService.selectedService = {
          service_id: 0,
          name: null,
          category: null,
          price_huf: 0,
          vat_rate: 0,
        }; */
      })
      .catch(() => {});
  }

  ngOnDestroy(): void {
    if (!this.queryParamSub) return;
    this.queryParamSub.unsubscribe();
  }
}
