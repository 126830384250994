import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { ArrivalCardComponent } from 'src/app/@components';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import {
  BookingService,
  CalendarService,
  DashboardService,
  DateService,
  FocusService,
  GuestService,
  LanguageService,
  RoomService,
  RouterService,
} from 'src/app/@services';

@Component({
  selector: 'booking-arrival',
  templateUrl: './booking-arrival.component.html',
  styleUrls: ['./booking-arrival.component.scss'],
})
export class BookingArrivalComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private guestService: GuestService,
    private routerService: RouterService,
    private dateService: DateService,
    public langService: LanguageService,
    private dashboardService: DashboardService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren('guestForms') guestForms: QueryList<ArrivalCardComponent>;
  public _isEditing: boolean;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  public selectedRoom: number;

  public guests: GuestInterface[] = [];
  public areFormsValid: boolean;
  private paramSub: Subscription;
  private bookingSub: Subscription;
  private params: any = {};

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      this.params = params;
    });
    this.bookingSub = this.bookingService.selectedBooking$.subscribe(() => {
      if (!Object.keys(this.bookingService.sidePanelRooms).length) return;
      this.loadData();
    });
  }

  public addSingleGuest(guest: GuestInterface): void {
    this.guestService
      .addGuests([guest], this.selectedRoom)
      .then(() => {
        this.getGuests();
      })
      .catch(() => {});
  }

  public checkValidations(): void {
    this.areFormsValid = this.guestForms.toArray().every((guestForm) => {
      return guestForm.guestForm.valid;
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public selectRoom(roomId: string): void {
    this.selectedRoom = Number(roomId);
    this.routerService.addQueryParams({ room: roomId });
    this.getGuests();
  }

  public arrive(): void {
    if (this.bookingService.sidePanelRooms[this.selectedRoom].arrived) return;
    this.guestService
      .arrive(this.selectedRoom)
      .then(() => {
        this.loadData();
        this.bookingService.getBookings();
        if (this.dashboardService.totalTasks)
          this.dashboardService.completedTasks++;
      })
      .catch(() => {});
  }

  private createCards(): void {
    const neededGuests =
      this.bookingService.sidePanelRooms[this.selectedRoom].adults +
      this.bookingService.sidePanelRooms[this.selectedRoom].children;

    this.guests = [];

    for (let i = 0; i < neededGuests; i++) {
      this.guests.push({
        guest_id: null,
        birth_place: null,
        birth_date: null,
        tax_exempt: false,
        tax_exemption_reason: null,
        room_id: this.selectedRoom,
        gender: null,
        country_of_residence: null,
        postcode_of_residence: null,
        nationality: null,
        birth_first_name: null,
        birth_last_name: null,
        mothers_first_name: null,
        mothers_last_name: null,
        visa_arrival_date: null,
        visa_arrival_place: null,
        visa_number: null,
        guest_arrival_id: null,
      });
    }
  }

  private fillCards(guests: GuestInterface[]): void {
    let i = 0;
    guests.forEach((guest) => {
      if (guest.room_id === this.selectedRoom) {
        if (guest.birth_date)
          guest.tax_exempt = !this.dateService.isOver18(guest.birth_date);
        this.guests[i] = guest;
        i++;
      }
    });
    const roomGuests = guests.filter(
      (guest) => guest.room_id === this.selectedRoom
    );

    this.guestService.totalGuestsToScan =
      this.guests.length - roomGuests.length;
  }

  private getGuests(): void {
    this.createCards();
    this.guestService
      .getGuests(this.params.hotel, this.params.booking)
      .then((guests: GuestInterface[]) => {
        this.fillCards(guests);
      })
      .catch(() => {});
  }

  public isArrivable(): boolean {
    if (
      !this.bookingService.sidePanelRooms[this.selectedRoom] ||
      this.bookingService.sidePanelRooms[this.selectedRoom].arrived
    )
      return false;

    for (let guest of this.guests) {
      if (!guest.guest_id) return false;
    }
    return true;
  }

  public loadData(): void {
    this.selectedRoom =
      Number(this.params.room) ??
      Number(this.bookingService?.selectedBooking?.room_id);

    this.getGuests();
  }

  ngOnDestroy(): void {
    this.roomsToDepart = [];
    this.paramSub && this.paramSub.unsubscribe();
    this.bookingSub && this.bookingSub.unsubscribe();
  }
}
