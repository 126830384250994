import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/@dialogs/confirmation/confirmation.dialog';
import { HotelService, LanguageService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'booking-room-card',
  templateUrl: './booking-room-card.component.html',
  styleUrls: ['./booking-room-card.component.scss'],
})
export class RoomPanelComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public hotelService: HotelService,
    private matDialog: MatDialog,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public dateService: DateService,
    public focusService: FocusService,
    public langService: LanguageService,
    public roomService: RoomService,
    public tableService: TableService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @Input() room: any;
  @Input() isEditing: boolean;
  public selectedLanguage: string;
  public bookingChanel: string;
  public _sidePanelType: SidePanelTypeEnum;
  public _isEditing: boolean;
  public isLoading: boolean;
  public roomSelectOptions: any[] = [
    {
      value: null,
      label: {
        hu: 'Válasszon egy szobát',
        en: 'Choose a room',
      },
      selected: true,
      disabled: true,
    },
  ];
  public ifa: number;
  public flat_ifa: number;
  public originalValues: any;
  public isPrepaymentEnabled: boolean;
  protected price: number;

  ngOnInit(): void {
    this.setSelectableRoomIds();
    this.ifa = this.hotelService.selectedHotel.IFA;
    this.flat_ifa = this.hotelService.selectedHotel.flat_IFA;
    this.isPrepaymentEnabled =
      this.hotelService.selectedHotel.prepayment_enabled;
    if (this.isEditing)
      this.originalValues = structuredClone(
        this.bookingService.sidePanelRooms[this.room.key]
      );
    if (!this.isEditing) this.roomSelectOptions[0].value = 0;
    this.price = this.bookingService.sidePanelRooms[this.room.key].ifa_included
      ? this.bookingService.sidePanelRooms[this.room.key].gross_price
      : this.bookingService.sidePanelRooms[this.room.key].gross_price -
        this.bookingService.sidePanelRooms[this.room.key].adults * this.ifa;
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public inputStartDate: any;
  public inputEndDate: any;
  public contactName: string;
  public people: number;

  public removeRoom(roomId: string): void {
    this.tableService.removeRoom(roomId);
  }

  public setRoomIdForCalendar(roomId: string): void {
    this.tableService.roomIdForCalendar = Number(roomId);
  }

  public setSelectableRoomIds(): void {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    /* const bookedRoomIds = Object.keys(this.bookingService.sidePanelRooms);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    ); */
    roomIds.forEach((roomID) => {
      this.roomSelectOptions.push({
        value: Number(roomID),
        label: {
          hu: this.roomService.getRoomName(roomID),
          en: this.roomService.getRoomName(roomID),
        },
      });
    });
  }

  public selectRoom(newRoomId: any, roomId: string): void {
    const room = this.roomService.rooms.find(
      (room) => room.room_id === Number(newRoomId)
    );
    const grossPrice = room?.default_price_huf ?? 0;
    console.log('gross ', grossPrice);

    this.bookingService.sidePanelRooms[newRoomId] = {
      arrival_date:
        this.bookingService.sidePanelRooms[roomId].arrival_date ??
        this.dateService.formatDate(new Date()),
      departure_date:
        this.bookingService.sidePanelRooms[roomId].departure_date ??
        this.dateService.addDayToStringDate(
          this.dateService.formatDate(new Date()),
          1
        ),
      adults:
        this.bookingService.sidePanelRooms[this.room.key].adults ??
        room.max_adults ??
        0,
      children: this.bookingService.sidePanelRooms[this.room.key].children ?? 0,
      gross_price: grossPrice,
      departed: false,
      paid: false,
      arrived: false,
      room_id: newRoomId,
      old_room_id:
        !this.bookingService.sidePanelRooms[roomId].newRoom &&
        !this.bookingService.sidePanelRooms[roomId].old_room_id
          ? Number(roomId)
          : this.bookingService.sidePanelRooms[roomId].old_room_id,
      contact_name:
        this.bookingService?.selectedBooking?.contact_name ??
        this.bookingService?.bookingData?.contact_name,
      ifa_included: this.hotelService?.selectedHotel?.flat_IFA
        ? room.ifa_included
        : true,
      newRoom: this.bookingService.sidePanelRooms[roomId].newRoom,
      prepayment: this.setPrepayment(),
    };

    delete this.bookingService.sidePanelRooms[roomId];
  }

  private setPrepayment(): number {
    let prepayment =
      this.bookingService.sidePanelRooms[this.room.key].prepayment;
    if (prepayment) return prepayment;

    if (!this.hotelService.selectedHotel.prepayment_enabled) return 0;
    let room = this.roomService.rooms.find(
      (room) => room.room_id === Number(this.room.key)
    );
    return this.hotelService.selectedHotel.prepayment_flat
      ? this.hotelService.selectedHotel.prepayment_flat
      : room.default_price_huf *
          this.hotelService.selectedHotel.prepayment_percent;
  }

  public addRoom(): void {
    this.isLoading = true;
    this.bookingService
      .addBookingRoom(this.bookingService.selectedBooking.booking_id, this.room)
      .then(async () => {
        this.isLoading = false;
        await this.bookingService.getBookings();
        this.bookingService.sidePanelRooms =
          this.bookingService.setSelectedBookingsRooms();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public editRoom(): void {
    this.isLoading = true;
    const tempSolution = structuredClone(this.room);
    tempSolution.value.gross_price = this.price;
    this.bookingService
      .putBookingRoom(
        this.bookingService.selectedBooking.booking_id,
        tempSolution
      )
      .then(async () => {
        this.isLoading = false;
        this.originalValues = structuredClone(
          this.bookingService.sidePanelRooms[this.room.key]
        );
        await this.bookingService.getBookings();
        this.bookingService.sidePanelRooms =
          this.bookingService.setSelectedBookingsRooms();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public deleteRoom(): void {
    if (
      !this.bookingService?.selectedBooking?.booking_id ||
      typeof this.bookingService.sidePanelRooms[this.room.key].room_id ===
        'string'
    ) {
      this.tableService.removeRoom(this.room.key);
    } else {
      this.matDialog
        .open(ConfirmationDialog)
        .afterClosed()
        .subscribe((choice: boolean) => {
          if (!choice) return;

          this.bookingService
            .deleteBookingRooms(
              this.bookingService.selectedBooking.booking_id,
              this.bookingService.sidePanelRooms[this.room.key].old_room_id ??
                this.room.key
            )
            .then(() => {
              this.tableService.removeRoom(this.room.key);
            })
            .catch(() => {});
        });
    }
  }

  public updateLocalPrice(): void {
    this.price = this.tableService.calculateRoomPrice(
      this.room.key,
      this.bookingService.sidePanelRooms[this.room.key].arrival_date,
      this.bookingService.sidePanelRooms[this.room.key].departure_date
    );
  }

  public addNight(): void {
    const date = new Date(
      this.bookingService.sidePanelRooms[this.room.key].departure_date
    );
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    this.bookingService.sidePanelRooms[this.room.key].departure_date =
      this.dateService.formatDate(newDate);

    this.price = this.tableService.calculateRoomPrice(
      this.room.key,
      this.bookingService.sidePanelRooms[this.room.key].arrival_date,
      this.bookingService.sidePanelRooms[this.room.key].departure_date
    );
    this.setGrossPrice();
  }

  public subtractNight(): void {
    if (
      this.calendarService.calculateDaysDifference(
        this.bookingService.sidePanelRooms[this.room.key].arrival_date,
        this.bookingService.sidePanelRooms[this.room.key].departure_date
      ) === 1
    ) {
      return;
    }
    const date = new Date(
      this.bookingService.sidePanelRooms[this.room.key].departure_date
    );
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    this.bookingService.sidePanelRooms[this.room.key].departure_date =
      this.dateService.formatDate(newDate);

    this.price = this.tableService.calculateRoomPrice(
      this.room.key,
      this.bookingService.sidePanelRooms[this.room.key].arrival_date,
      this.bookingService.sidePanelRooms[this.room.key].departure_date
    );
    this.setGrossPrice();
  }

  public changeIfaIncluded(): void {
    this.bookingService.sidePanelRooms[this.room.key].ifa_included =
      !this.bookingService.sidePanelRooms[this.room.key].ifa_included;
    this.setGrossPrice();
  }

  public isBookingModified(): boolean {
    return (
      JSON.stringify(this.bookingService.sidePanelRooms[this.room.key]) !==
        JSON.stringify(this.originalValues) ||
      this.bookingService.sidePanelRooms[this.room.key].newRoom ||
      this.bookingService.sidePanelRooms[this.room.key].old_room_id !==
        undefined
    );
  }

  public cancelModification(): void {
    this.bookingService.sidePanelRooms[this.room.key] = this.originalValues;
  }

  protected setGrossPrice(): void {
    this.bookingService.sidePanelRooms[this.room.key].gross_price = this
      .bookingService.sidePanelRooms[this.room.key].ifa_included
      ? this.price
      : this.price +
        (this.flat_ifa
          ? this.ifa *
            this.calendarService.calculateDaysDifference(
              this.bookingService.sidePanelRooms[this.room.key].arrival_date,
              this.bookingService.sidePanelRooms[this.room.key].departure_date
            ) *
            this.bookingService.sidePanelRooms[this.room.key].adults
          : this.price * this.ifa * 100);
  }
}
