import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { IResponse, LoginResInterface } from '../@Interfaces';
import { ConfigService } from './config.service';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private RouterService: RouterService,
    private configService: ConfigService
  ) {}

  public IsAuthenticated: boolean;
  public isPremium: boolean;
  public roles: { [key: number]: LoginResInterface } = {};
  private tokenSubject = new BehaviorSubject<string | null>(null);

  public async signIn(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<LoginResInterface[]>>(
          `${this.configService.server}api/auth`,
          body
        )
        .subscribe({
          next: (res: IResponse<LoginResInterface[]>) => {
            this.IsAuthenticated = true;
            this.RouterService.absoluteNavigation('home');
            res.data.forEach((group) => {
              this.roles[group.hotel_id] = group;
            });
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public setToken(token: string | null): void {
    this.tokenSubject.next(token);
  }

  public getToken(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  public isAuthenticatedAsync(): Observable<boolean> {
    return this.http
      .post<IResponse<{ success: boolean; token: string }>>(
        `${this.configService.server}api/auth/refresh`,
        {}
      )
      .pipe(
        map(() => true),
        catchError(() => {
          return of(false);
        })
      );
  }

  // TODO: this somehow redirects to the calendar
  public signOut(): void {
    this.http
      .post<IResponse>(`${this.configService.server}api/auth/sign-out`, {})
      .subscribe({
        next: () => {
          this.IsAuthenticated = false;
          this.RouterService.absoluteNavigationNoParamPreserve('login');
        },
        error: () => {
          console.log('sign out error');
        },
      });
  }

  public async register(body: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse>(`${this.configService.server}api/register`, body)
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
