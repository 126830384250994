import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
  taxObjectsOptions,
  taxZoneOptions,
} from 'src/app/@consts';
import { BookingDetailsInterface } from 'src/app/@Interfaces/booking.interface';
import {
  HotelService,
  LanguageService,
  RouterService,
} from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'booking-new-details',
  templateUrl: './booking-new-details.component.html',
  styleUrls: ['./booking-new-details.component.scss'],
})
export class BookingNewDetailsComponent {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    private calendarService: CalendarService,
    public roomService: RoomService,
    private routerService: RouterService,
    public hotelService: HotelService,
    public langService: LanguageService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public selectedLanguage: string;
  public bookingDetails: BookingDetailsInterface = {
    channel: null,
    contact_email: null,
    contact_name: null,
    contact_phone: null,
    group_booking: false,
    language: null,
    nationality: null,
    source: null,
    work_related: false,
    external_id: null,
    company_name: null,
    tax_number: null,
    tax_zone: null,
    booking_number: null,
    uuid: null,
  };

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly hotelLanguages = this.hotelService.selectedHotel.languages;
  public readonly hotelCountries = this.hotelService.selectedHotel.countries;
  public readonly nationalityOptions = nationalityOptions;
  protected readonly taxZoneOptions = taxZoneOptions;
  protected readonly taxObjectOptions = taxObjectsOptions;

  public languageOptions = [{}];

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  protected resetCompanyDetails(isNeeded: boolean): void {
    if (isNeeded) return;
    this.bookingDetails.tax_number = null;
    this.bookingDetails.tax_zone = null;
    this.bookingDetails.company_name = null;
  }

  public nextStep(event?: Event): void {
    event.stopPropagation();
    this.bookingService.saveBookingData(this.bookingDetails);
    this.calendarService.setAvailableDayClasses();

    this.bookingService.sidePanelRooms = {};
    this.routerService.absoluteNavigation('calendar/room');
  }

  public closeBooking(): void {
    this.bookingChanel = null;
    this.routerService.absoluteNavigationNoParamPreserve('calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    this.calendarService.removeAvailableDayClasses();
    this.selectedLanguage = null;
  }

  public setCommunicationLang(val: any): void {
    //TODO: handle countries with same lang
    if (this.hotelService.selectedHotel.languages.includes(val)) {
      this.bookingDetails.language = val;
    } else if (this.hotelService.selectedHotel.languages.includes('EN')) {
      this.bookingDetails.language = 'EN';
    } else {
      this.bookingDetails.language = 'HU';
    }
  }
}
