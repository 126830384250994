import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { paymentOptions, vatRateOptions } from 'src/app/@consts';
import { InvoiceDialog } from 'src/app/@dialogs/invoice/invoice.dialog';
import { ServiceSelectDialog } from 'src/app/@dialogs/service-select/service-select';
import { ChargeInterface, ServiceInterface } from 'src/app/@Interfaces';
import {
  BookingService,
  CalendarService,
  ChargeService,
  DateService,
  FocusService,
  LanguageService,
  RoomService,
  RouterService,
  ServiceService,
} from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';

@Component({
  selector: 'booking-rooms-payment',
  templateUrl: './booking-rooms-payment.component.html',
  styleUrls: ['./booking-rooms-payment.component.scss'],
})
export class BookingRoomsPaymentComponent implements OnInit, OnDestroy {
  constructor(
    private chargeService: ChargeService,
    private matDialog: MatDialog,
    private routerService: RouterService,
    private serviceService: ServiceService,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public dateService: DateService,
    public focusService: FocusService,
    public roomService: RoomService,
    public langService: LanguageService
  ) {}

  public readonly paymentOptions: any = paymentOptions;
  public readonly vatRateOptions: any = vatRateOptions;
  public isLoading: boolean;

  public charges: ChargeInterface[];
  public totalPrice: number;
  public paidAmount: number;
  public remainingAmount: number;
  public selectedRoom: number;
  public areAllRoomsSelected: boolean;
  public chargeTypes = ChargeTypes;
  private paramSub: Subscription;
  private params: any = {};

  @ViewChild('groupForm') groupForm: any;

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      if (!params) return;
      this.params = params;
    });
    setTimeout(() => {
      this.getCharges(this.params.hotel, this.params.booking);
      this.selectRoom(this.params.room);
      //TODO: maybe move to routing service
    }, 10);
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public addCharge(): void {
    this.matDialog
      .open(ServiceSelectDialog, { data: this.serviceService.services })
      .afterClosed()
      .subscribe((service: ServiceInterface) => {
        if (!service) return;
        this.getCharges();
      });
  }

  public getCharges(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): void {
    this.chargeService
      .getCharges(hotelId, bookingId)
      .then((charges: ChargeInterface[]) => {
        this.groupForm.value.group_payment_date = this.dateService.formatDate(
          new Date()
        );

        this.setPayDayToday(charges);
        this.charges = charges;
        this.setChargeSummary();
        this.remainingAmountForRoom();
      })
      .catch((err) => {
        console.log('get charges error', err);
      });
  }

  public selectRoom(roomId: string): void {
    this.selectedRoom = Number(roomId);
    this.routerService.addQueryParams({ room: roomId });
    this.setChargeSummary();
  }

  public async payRoom(roomId: string): Promise<void> {
    this.isLoading = true;
    this.bookingService
      .payRoom(this.bookingService.selectedBooking.booking_id, {
        rooms: [
          {
            room_id: Number(roomId),
            gross_price: this.bookingService.sidePanelRooms[roomId].gross_price,
          },
        ],
      })
      .then(async () => {
        this.isLoading = false;
        this.bookingService.getBookings().catch(() => {});
        this.getCharges();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public formatDate(charge: ChargeInterface, date: Date): void {
    charge.payment_date = this.dateService.formatDate(date);
  }

  public setVatIfKnown(charge: ChargeInterface): void {
    if (charge.charge_type === this.chargeTypes.prepayment) {
      charge.vat_rate = 5;
    }
  }

  public payAllCharges(form: {
    group_payment_method: string;
    group_payment_date: Date;
  }): void {
    const requestBody: { charges: any[] } = { charges: [] };
    this.charges.forEach((charge) => {
      if (
        (this.areAllRoomsSelected || charge.room_id === this.selectedRoom) &&
        !charge.paid
      ) {
        requestBody.charges.push({
          charge_id: charge.charge_id,
          payment_method: form.group_payment_method,
          payment_date: this.dateService.formatDate(
            new Date(form.group_payment_date)
          ),
        });
      }
    });
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.getCharges();
        this.bookingService.getBookings().catch(() => {});
      })
      .catch(() => {});
  }

  public selectAllRooms(): void {
    this.areAllRoomsSelected = true;
    this.setChargeSummary();
  }

  public deselectAllRooms(): void {
    this.areAllRoomsSelected = false;
    this.setChargeSummary();
  }

  public remainingAmountForRoom(): void {
    let remainingAmounts: { [key: string]: number } = {};

    this.charges.forEach((charge: ChargeInterface) => {
      if (charge.paid) return;
      if (!remainingAmounts[charge.room_id.toString()])
        remainingAmounts[charge.room_id.toString()] = 0;
      remainingAmounts[charge.room_id.toString()] +=
        charge.gross_price * charge.qty;
    });

    for (const key in this.bookingService.sidePanelRooms) {
      this.bookingService.sidePanelRooms[key].remainingAmount =
        remainingAmounts[key];
    }
  }

  public setAllPaymentMethod(paymentMethod: any): void {
    if (!this.charges) return;

    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_method = paymentMethod;
      }
      return charge;
    });
  }

  public setAllPaymentDate(paymentDate: any): void {
    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_date = paymentDate;
      }
      return charge;
    });
  }

  public openInvoiceDialog(): void {
    this.matDialog.open(InvoiceDialog, { data: this.charges });
  }

  public refreshData(): void {
    this.getCharges();
    this.bookingService.getBookings().catch(() => {});
  }

  private setChargeSummary(): void {
    this.totalPrice = 0;
    this.paidAmount = 0;
    this.remainingAmount = 0;
    if (!this.charges) return;
    this.charges.forEach((charge: ChargeInterface) => {
      if (this.selectedRoom !== charge.room_id && !this.areAllRoomsSelected) {
        return;
      }
      this.totalPrice += charge.gross_price * charge.qty;
      if (charge.paid) {
        this.paidAmount += charge.gross_price * charge.qty;
      } else {
        this.remainingAmount += charge.gross_price * charge.qty;
      }
    });
  }

  private setPayDayToday(charges: ChargeInterface[]): void {
    charges.map((charge) => {
      if (!charge.paid)
        charge.payment_date = this.dateService.formatDate(new Date());
    });
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
