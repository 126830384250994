<div class="support-wrapper">
  <h1>{{ langService.dictionary["help"][langService.lang] }}</h1>
  <div class="premium-support">
    <img src="assets/signal.png" />
    <p>
      {{ langService.dictionary["support-signal-1"][langService.lang] }}
      <a target="_blank" href="https://signal.org/download/">Signal</a>
      {{ langService.dictionary["support-signal-2"][langService.lang] }}
    </p>
  </div>
  <div class="form-wrapper">
    <h2>
      {{ langService.dictionary["support-submit-request"][langService.lang] }}
    </h2>
    <p>
      {{ langService.dictionary["support-request-tooltip"][langService.lang] }}
    </p>
    <form #supportForm="ngForm">
      <custom-select
        type="dropdown"
        class="form-dropdown-select"
        name="subject"
        [required]="true"
        [(ngModel)]="subject"
        [label]="
          langService.dictionary['support-subject'][langService.lang] + '*'
        "
        [options]="helpSubjectOptions"
      />
      <custom-select
        type="dropdown"
        class="form-dropdown-select"
        name="priority"
        [required]="true"
        [(ngModel)]="priority"
        [label]="
          langService.dictionary['support-priority'][langService.lang] + '*'
        "
        [options]="
          hotelService?.selectedHotel?.premium
            ? helpPriorityOptions
            : freeHelpPriorityOptions
        "
        [disabled]="!hotelService?.selectedHotel?.premium"
      />
      <div class="form-textbox focused">
        <textarea
          type="text"
          id="comment"
          name="comment"
          required
          [(ngModel)]="message"
        ></textarea>
        <span class="form-textbox-label">{{
          langService.dictionary["support-message"][langService.lang]
        }}</span>
      </div>
      <button
        class="primary-button"
        [disabled]="supportForm.invalid"
        (click)="sendTicket()"
      >
        {{ langService.dictionary["submit"][langService.lang] }}
      </button>
    </form>
  </div>

  <div class="referral-wrapper">
    <h2>{{ langService.dictionary["referral"][langService.lang] }}</h2>
    <p>{{ langService.dictionary["referral-text"][langService.lang] }}</p>
    <div class="referral-code-block">
      <p>{{ langService.dictionary["referral-code"][langService.lang] }}</p>
      <div class="referral-code">TODO</div>
    </div>
  </div>

  <div class="user-guide-wrapper">
    <header>
      <h2>Kézikönyv</h2>
      <p>
        A kis portás kézikönyv segít bemutatni a program működését és funkcióit.
      </p>
    </header>
    <nav>
      <ul>
        <li>
          <a
            [routerLink]="['.']"
            fragment="calendar"
            (click)="routerService.scrollToElement(calendar)"
            >Naptár</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="calendar-navigation"
                (click)="routerService.scrollToElement(calendarNavigation)"
                >Navigáció</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="calendar-new-booking"
                (click)="routerService.scrollToElement(calendarNewBooking)"
                >Új foglalás felvétele</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="calendar-daily-conclude"
                (click)="routerService.scrollToElement(calendarDailyConclude)"
                >Napi zárás</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="calendar-compact-view"
                (click)="routerService.scrollToElement(calendarCompactView)"
                >Kompakt nézet</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="calendar-custom-holidays"
                (click)="routerService.scrollToElement(calendarCustomHolidays)"
                >Egyedi ünnepnapok</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="bookings"
            (click)="routerService.scrollToElement(bookings)"
            >Foglalások</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="bookings-new-booking"
                (click)="routerService.scrollToElement(bookingsNewBooking)"
                >Új foglalás felvétele</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="bookings-modify-booking-contact"
                (click)="
                  routerService.scrollToElement(bookingsModifyBookingContact)
                "
                >Foglaló adatainak módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="bookings-modify-booking"
                (click)="routerService.scrollToElement(bookingsModifyBooking)"
                >Foglalás módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="payment-modify"
                (click)="routerService.scrollToElement(paymentModify)"
                >Árak módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="bookings-delete"
                (click)="routerService.scrollToElement(bookingsDelete)"
                >Foglalás törlése</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="check-in"
            (click)="routerService.scrollToElement(checkIn)"
            >Érkeztetés (check-in)</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="checkInScanner"
                (click)="routerService.scrollToElement(checkInScanner)"
                >Szkenner</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="checkInDesktop"
                (click)="routerService.scrollToElement(checkInDesktop)"
                >Számítógépes check-in</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="check-in-mobile"
                (click)="routerService.scrollToElement(checkInMobile)"
                >Mobilos check-in</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="check-in-manual"
                (click)="routerService.scrollToElement(checkInManual)"
                >Manuális check-in</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="check-in-results"
                (click)="routerService.scrollToElement(checkInResults)"
                >Check-in hatásai</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="payment"
            (click)="routerService.scrollToElement(payment)"
            >Fizettetés</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="payment-modify"
                (click)="routerService.scrollToElement(paymentModify)"
                >Összeg módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="payment-pay-all"
                (click)="routerService.scrollToElement(paymentPayAll)"
                >Tömeges fizettetés</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="payment-pay-one"
                (click)="routerService.scrollToElement(paymentPayOne)"
                >Egyéni fizettetés</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="payment-add-service"
                (click)="routerService.scrollToElement(paymentAddService)"
                >Szolgáltatások hozzáadása</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="check-out"
            (click)="routerService.scrollToElement(checkOut)"
            >Távoztatás (check-out)</a
          >
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="rooms"
            (click)="routerService.scrollToElement(rooms)"
            >Szobák</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="rooms-overview"
                (click)="routerService.scrollToElement(roomsOverview)"
                >Szobák áttekintése</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="rooms-add"
                (click)="routerService.scrollToElement(roomsAdd)"
                >Szoba hozzáadása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="rooms-modify"
                (click)="routerService.scrollToElement(roomsModify)"
                >Szoba módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="rooms-ooo"
                (click)="routerService.scrollToElement(roomsOoo)"
                >Szoba üzemen kívül helyezése</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="rooms-oos"
                (click)="routerService.scrollToElement(roomsOos)"
                >Szoba használaton kívül helyezése</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="services"
            (click)="routerService.scrollToElement(services)"
            >Szolgáltatások</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="services-overview"
                (click)="routerService.scrollToElement(servicesOverview)"
                >Szolgáltatások áttekintése</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="services-add"
                (click)="routerService.scrollToElement(servicesAdd)"
                >Szolgáltatás hozzáadása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="services-modify"
                (click)="routerService.scrollToElement(servicesModify)"
                >Szolgáltatás módosítása</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="services-delete"
                (click)="routerService.scrollToElement(servicesDelete)"
                >Szolgáltatás törlése</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            [routerLink]="['.']"
            fragment="automation"
            (click)="routerService.scrollToElement(automation)"
            >Automatizációk</a
          >
          <ul>
            <li>
              <a
                [routerLink]="['.']"
                fragment="automation-daily-conclude"
                (click)="routerService.scrollToElement(automationDailyConclude)"
                >Automata napi zárás</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="automation-delayed-conclude"
                (click)="
                  routerService.scrollToElement(automationDelayedConclude)
                "
                >Késleltetett napi zárás</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="automation-check-in"
                (click)="routerService.scrollToElement(automationCheckIn)"
                >Automata check-in</a
              >
            </li>
            <li>
              <a
                [routerLink]="['.']"
                fragment="automation-check-out"
                (click)="routerService.scrollToElement(automationCheckOut)"
                >Automata check-out</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <hr />
    <section id="calendar" #calendar>
      <h3>Naptár</h3>
      <p>
        A naptár oldal a lelke a kis portás rendszernek. Itt lehet áttekintést
        kapni a foglalásokról, új foglalást felvenni, meglévő foglalást
        módosítani, valamint napot zárni.
      </p>
      <h4 id="calendar-navigation" #calendarNavigation>Navigáció</h4>
      <div class="image-wrapper">
        <img src="assets/user-guide/calendar/navigation.jpg" />
      </div>
      <p>
        A dátumválasztó a bal felső sarokban található. Ebbe be lehet írni egy
        konkrét dátumot. A naptár ikonra kattintva egy sokkal
        felhasználóbarátabb ablakban lehet dátumot választani. Valamint a dátum
        mező mellett lévő nyilakkal is könnyedén lehet a naptárt görgetni.
      </p>
      <h4 id="calendar-new-booking" #calendarNewBooking>
        Új foglalás felvétele
      </h4>
      <div class="image-wrapper">
        <img src="assets/user-guide/calendar/new-booking.jpg" />
      </div>
      <p>
        Felül középen található az "Új foglalás" gomb, amivel elindítható az új
        foglalás felvétele folyamat. Részletesebb leírás a
        <a
          [routerLink]="['.']"
          fragment="bookings-new-booking"
          (click)="routerService.scrollToElement(bookingsNewBooking)"
          >Foglalások</a
        >
        szekcióban
      </p>
      <h4 id="calendar-daily-conclude" #calendarDailyConclude>Napi zárás</h4>
      <div class="image-wrapper">
        <img src="assets/user-guide/calendar/daily-conclude-1.jpg" />
        <img src="assets/user-guide/calendar/daily-conclude-2.jpg" />
      </div>
      <p>
        A jobb felső sarokban található a napi zárás gomb. Ezen jelezve van a
        lezárandó dátum. Erre kattintva felugrik egy megerősítő ablak, amit
        elfogadva lehet napot zárni. A napi zárás feltétele, hogy az adott napon
        érkező vendégek mind meg lettek érkeztetve, és a távozó vendégek el
        lettek távoztatva. Az érkeztetés, távoztatás, valamint a napi zárás
        automatizálásáról többet tudhat meg az
        <a
          [routerLink]="['.']"
          fragment="automation"
          (click)="routerService.scrollToElement(automation)"
          >Automatizációk</a
        >
        szekcióban.
      </p>
      <h4 id="calendar-compact-view" #calendarCompactView>Kompakt nézet</h4>
      <div class="image-wrapper">
        <img src="assets/user-guide/calendar/compact-view-1.jpg" />
        <img src="assets/user-guide/calendar/compact-view-2.jpg" />
      </div>
      <p>
        A naptár bal felső cellájában található gombbal vezérelhető a kompakt
        mód. Ez különösen hasznos lehet azok számára, akik sok szobát kezelnek,
        és könnyebben szeretnék átlátni a foglalásokat
      </p>
      <h4 id="calendar-custom-holidays" #calendarCustomHolidays>
        Egyedi ünnepnapok
      </h4>
      <div class="image-wrapper">
        <img src="assets/user-guide/calendar/custom-holiday-1.jpg" />
        <img src="assets/user-guide/calendar/custom-holiday-2.jpg" />
      </div>
      <p>
        Prémium csomaggal egy adott napra kattintva meg lehet jelölni egyedi
        ünnepnek. Különösen hasznos lehet a környéken tartott fesztiválokat és
        különböző eseményeket megjelölni, és nagyobb mennyiségű foglalásra
        számítani ezeken a napokon.
      </p>
    </section>
    <hr />
    <section id="bookings" #bookings>
      <h3>Foglalások</h3>
      <p>
        A foglalásokat a naptár oldalról lehet elérni. Egy foglalásra kattintva
        lehet kiválasztani azt, és itt lehet adatokat módosítani, érkeztetni,
        fizettetni, távoztatni, és törölni
      </p>
      <h4 id="bookings-new-booking" #bookingsNewBooking>Új foglalás</h4>
      <p>
        Új foglalás felvételét a "Naptár" oldalról lehet kezdeményezni. Ennek az
        első lépése a foglaló adatainak megadása. Második lépése pedig a
        foglaláshoz a szobák felvétele. A "Foglalás rögzítése" gomb ez után
        beilleszti a naptárba a foglalást és átirányít a fizettetés oldalra,
        ahol könnyedén módosítani tud az árakon ha ez szükséges.
      </p>
      <h4 id="bookings-modify-booking-contact" #bookingsModifyBookingContact>
        Foglaló adatainak módosítása
      </h4>
      <p>
        A foglaló adatait az "Adatok" menü alatt tudja megtekinteni, valamint
        módosítani is itt lehet.
      </p>
      <h4 id="bookings-modify-booking" #bookingsModifyBooking>
        Foglalás módosítása
      </h4>
      <p>
        A foglalást a "Szobák" menü alatt lehet módosítani. Az érkezés dátumát
        és a vendégek számát addig lehet módosítani, ameddig a szobába nem
        érkeztek meg a vendégek. A távozás dátumát pedig addig lehet módosítani,
        ameddig a vendégek nem lettek eltávoztatva.
      </p>
      <h4 id="bookings-delete" #bookingsDelete>Foglalás törlése</h4>
      <p>
        Amennyiben a foglalásból még 1 vendég sem érkezett meg, abban az esetben
        lehetőség van a foglalás törlésére a "Törlés" gombbal.
      </p>
    </section>
    <hr />
    <section id="check-in" #checkIn>
      <h3>Érkeztetés (check-in)</h3>
      <p>
        Érkeztetésre 3 módot kínál fel a kis portás. Mind a 3 módszer továbbítja
        az adatokat a VIZA és NTAK számára.
      </p>
      <h4 id="check-in-scanner" #checkInScanner>Szkenner</h4>
      <p>
        A szkenner felső sorában lehet váltani a beolvasott igazolvány típusát.
        A gombra kattintva lehet személyi és útlevél közül választani. Van olyan
        telefon amin több kamera is elérhető. Ebben az esetben a jobb felső
        sarokban található kamera gombbal lehet ezek közül választani. Személyi
        igazolvány esetén csak a hátoldalt kell szkennelni.
      </p>
      <h4 id="check-in-desktop" #checkInDesktop>Számítógépes check-in</h4>
      <p>
        A webes felületen nyissa meg a foglalást, és navigáljon a check-in
        oldalra. Nyissa meg a kis portás app-et a telefonján és kattintson a
        "Scan" gombra a felső menü sorban. Olvassa be a webes QR kódot, ezt
        követően pedig a szoba vendégeinek az igazolványait. Ha ez megtörtént a
        telefonon felugrik egy siker ablak, és ez után ha a weben a felül
        található "Szkennelve" gombra kattint, akkor ott meg fognak jelenni a
        beszkennelt adatok. Itt lehetőség van az adatok javítására és mentésére.
        Miután az összes adat mentésre került, az alsó sorban található
        "Érkeztetés" gombbal tudja véglegesíteni a folyamatot.
      </p>
      <h4 id="check-in-mobile" #checkInMobile>Mobilos check-in</h4>
      <p>
        Nyissa meg a kis portás app-et a telefonján, keresse meg a foglalást a
        naptárban és kattintson a check-in gombra az alsó menüben. Kattintson a
        "Scan" gombra, majd ezt követően olvassa be a szoba vendégeinek az
        igazolványait. Ha ez megtörtént a telefonon felugrik egy siker ablak, és
        ezt követően a check-in oldalon meg fognak jelenni a beszkennelt adatok.
        Itt lehetőség van az adatok javítására és mentésére. Miután az összes
        adat mentésre került, az alsó sorban található "Érkeztetés" gombbal
        tudja véglegesíteni a folyamatot.
      </p>
      <h4 id="check-in-manual" #checkInManual>Manuális check-in</h4>
      <p>
        Amennyiben nem szükséges, vagy nincs lehetőség a vendég igazolványának
        szkennelésére, a "Kézi felvétel" gombbal lehet kézzel felvinni vendég
        adatokat. Miután az összes adat meg lett adva és el lett mentve, az
        oldal alján lévő "Érkeztetés" gombbal lehet véglegesíteni a folyamatot.
      </p>
      <h4 id="check-in-results" #checkInResults>Check-in hatásai</h4>
      <p>
        Amennyiben van olyan vendég akinek érkezéskor kiderül, hogy IFA mentes
        és ezt megjelöli, akkor a rendszer automatikusan újraszámolja az IFA
        árát a háttérben, úgyhogy ezzel nem kell külön foglalkozni.
      </p>
    </section>
    <hr />
    <section id="payment" #payment>
      <h3>Fizettetés</h3>
      <p>
        A foglaláson a "Fizettetés" menüpontban tudja módosítani a vendég által
        fizetendő tételeket, és ezeknek a fizettetését és esetleges számlázását
        is innen tudja intézni.
      </p>
      <h4 id="payment-modify" #paymentModify>Összeg módosítása</h4>
      <p>
        Amennyiben egy tétel még nem lett kifizetve, van lehetőség ennek a
        módosítására. Ehhez csak annyit kell tenni, hogy a kívánt mezőt átírja,
        majd a "Módosít" gombra kattint.
      </p>
      <h4 id="payment-pay-all" #paymentPayAll>Tömeges fizettetés</h4>
      <p>
        A felső sávban lévő "Tömeges műveletek" lehetőséget adnak arra, hogy az
        összes tételt egyszerre fizettesse. Mindössze a fizetési dátumot és
        módot kell kiválasztani. Ezt követően pedig az "Összes kifizetése" gomb
        az összes tételt kifizetteti ezzel a dátummal és fizetési móddal.
      </p>
      <h4 id="payment-pay-one" #paymentPayOne>Egyéni fizettetés</h4>
      <p>
        Amennyiben a vendég más napokon fizet, vagy például a szállásdíjat
        kártyával és az IFÁ-t készpénzzel fizeti, van lehetőség a tételek
        külön-külön fizettetésére. Itt a tétel kártyán kell kiválasztani a
        fizetési dátumot és módot, majd a kártya jobb alsó sarkában lévő "Fizet"
        gombra kattintva rendezheti a fizetést.
      </p>
      <h4 id="payment-add-service" #paymentAddService>
        Szolgáltatások hozzáadása
      </h4>
      <p>
        Van lehetőség a standard szálláshely díj és IFA mellett előre definiált
        <a
          [routerLink]="['.']"
          fragment="services"
          (click)="routerService.scrollToElement(services)"
          >Szolgáltatásokat</a
        >
        is hozzáadni a szobákhoz.
      </p>
    </section>
    <hr />
    <section id="check-out" #checkOut>
      <h3>Távoztatás (check-out)</h3>
      <p>
        A távoztatás feltételei, hogy az adott szoba vendégei megérkeztek, és
        rendezték a tartozásukat. A jobb felső sarokban lévő "Összes
        kiválasztása" gombbal könnyedén ki lehet választani az összes szobát,
        vagy a szobákra kattintva ez egyesével is megtehető. Ezt követően a jobb
        alsó sarokban lévő "Távoztatás" gomb eltávoztatja a kiválasztott szobák
        vendégeit. Van lehetőség ennek a folyamatnak az automatizációjára,
        amiről az
        <a
          [routerLink]="['.']"
          fragment="automation-check-out"
          (click)="routerService.scrollToElement(automationCheckOut)"
          >Automata check-out</a
        >
        szekcióban tudhat meg többet.
      </p>
    </section>
    <hr />
    <section id="rooms" #rooms>
      <h3>Szobák</h3>
      <p>
        A szálláshelyhez tartozó szobákat az Admin főmenü Szobák füle alatt
        találja.
      </p>
      <h4 id="rooms-overview" #roomsOverview>Szobák áttekintése</h4>
      <p>Az áttekintés oldalon egy gyors pillantást vethet a szobákra.</p>
      <h4 id="rooms-add" #roomsAdd>Szoba hozzáadása</h4>
      <p>A "Hozzáadás" gombbal vehet fel szobákat.</p>
      <h4 id="rooms-modify" #roomsModify>Szoba módosítása</h4>
      <p>
        A módosítás oldalon a bal oldali oszlopban válassza ki a módosítani
        kívánt szobát, majd a jobb oldali oszlopban végezze el a módosításokat.
        Ezt követően a "Módosít" gombbal mentheti ezeket a változtatásokat
      </p>
      <h4 id="rooms-ooo" #roomsOoo>Szoba üzemen kívül helyezése</h4>
      <p>
        Az üzemen kívül azt jelenti, hogy a szobát nem lehet kiadni. Ilyen
        státuszt az "Üzemen kívül" pont alatt lehet felvenni. Válassza ki a
        szobát a bal oszlopban, majd a jobb oldali oszlop alján állítsa be a
        kezdő és vég dátumot és kattintson a "Hozzáad" gombra.
      </p>
      <h4 id="rooms-oos" #roomsOos>Szoba használaton kívül helyezése</h4>
      <p>
        A használaton kívül azt jelenti, hogy a szobával valami probléma van, de
        ennek ellenére kiadható. Ilyen státuszt az "Használaton kívül" pont
        alatt lehet felvenni. Válassza ki a szobát a bal oszlopban, majd a jobb
        oldali oszlop alján állítsa be a kezdő és vég dátumot és kattintson a
        "Hozzáad" gombra.
      </p>
    </section>
    <hr />
    <section id="services" #services>
      <h3>Szolgáltatások</h3>
      <p>
        A szolgáltatásokat az admin főmenü szolgáltatások füle alatt találni.
        Itt lehet szolgáltatásokat (reggeli, szauna, pótágy, stb.) felvenni,
        módosítani, és törölni. Amikor a fizettetés oldalon új tételt ad a
        foglaláshoz, ezek közül a szolgáltatások közül tud választani.
      </p>
      <h4 id="services-overview" #servicesOverview>
        Szolgáltatások áttekintése
      </h4>
      <p>Ez egy átfogó képet ad a szálláshelyhez tartozó szolgáltatásokról.</p>
      <h4 id="services-add" #servicesAdd>Szolgáltatás hozzáadása</h4>
      <p>A 4 kötelező mező kitöltése után kattintson a hozzáadás gombra.</p>
      <h4 id="services-modify" #servicesModify>Szolgáltatás módosítása</h4>
      <p>
        Kattintson a módosítani kívánt szolgáltatásra, majd a módosítások után
        kattintson a Módosítás gombra.
      </p>
      <h4 id="services-delete" #servicesDelete>Szolgáltatás törlése</h4>
      <p>
        Az áttekintés oldalon a törölni kívánt szolgáltatásnál kattintson a jobb
        alsó sarokban lévő kuka gombra, majd erősítse meg törlési szándékát a
        felugró ablakban.
      </p>
    </section>
    <hr />
    <section id="automation" #automation>
      <h3>Automatizációk</h3>
      <p>
        A kis portás automatizációi csökkentik az adminisztrációs feladatait.
        Ezeket a beállításokat az admin főmenü alatt, a hotel fülön találja.
      </p>
      <h4 id="automation-daily-conclude" #automationDailyConclude>
        Automata napi zárás
      </h4>
      <p>
        Minden nap végén a rendszer automatikusan lezárja az adott napot. Ez
        csak akkor tud megtörténni, ha az adott napra minden check-in és
        check-out el van rendezve. Amennyiben ez nem teljesül, a rendszer egy
        emailben értesíti a hibáról, hogy azt orvosolni tudja és így elkerülve
        azt, hogy beragadjon egy nap.
      </p>
      <h4 id="automation-delayed-conclude" #automationDelayedConclude>
        Késleltetett napi zárás
      </h4>
      <p>
        Ezt bekapcsolva az automata napi zárás nem a mai napot fogja lezárni,
        hanem a 3 nappal ezelőttit. Ez akkor lehet hasznos, ha az automata napi
        zárás be van kapcsolva és egy adott nap kimarad egy foglalásnak a
        felvétele és másnap már nem lehet felvinni a lezárt napra. Így marad egy
        3 napos különbség a mai nap és az utolsó lezárt nap között és ilyen
        hibákat korrigálni lehet, és az NTAK sem fog panaszkodni, hogy
        visszamenőleg túl sok nap nincs lezárva.
      </p>
      <h4 id="automation-check-in" #automationCheckIn>Automata check-in</h4>
      <p>
        Amennyiben minden vendégadat jelen van a rendszerben, a kis portás
        automatikusan megjelöli őket érkeztetettnek 22:00-kor
      </p>
      <h4 id="automation-check-out" #automationCheckOut>Automata check-out</h4>
      <p>
        A rendszer automatikusan elutaztatja a vendégeket a távozásuk napján
        10:00-kor
      </p>
    </section>
  </div>

  <div class="dev-diary-wrapper">
    <h2>Fejelsztési napló</h2>
    <h3>Jelenleg folyamatban lévő fejlesztések</h3>
    <ul>
      <li>Channel manager</li>
      <li>Interaktív kézikönyv</li>
    </ul>
    <h3>Elkészült fejlesztések</h3>
    <ul>
      <li>Csoportos dátumválasztó</li>
      <li>Sötét mód</li>
      <li>Vezérlőpult</li>
      <li>Előleg funkciók</li>
      <li>Szkenner szövegfelismerésének javítása</li>
      <li>Self check-in</li>
      <li>Ország választó testreszabása</li>
      <li>Kézikönyv</li>
      <li>
        Admin oldalon az áttekintésre kattintással azonnal a módosítás oldalra
        navigálás
      </li>
      <li>Manuális érkeztetés</li>
      <li>Beépített help desk</li>
      <li>18 év alatt, a vendég IFA mentességét autómatikusan kitölti</li>
      <li>Érkeztetési felület egyszerűsítése</li>
      <li>Autómata számlázás modul</li>
      <li>Beépített help desk</li>
      <li>Autómata havi IFA kimutatás email értesítés</li>
      <li>Szolgáltatások funkció</li>
      <li>Fizettetés oldal kibővítése</li>
      <li>Új foglalás automatikus adat kitöltés (ár, IFA, vendégek száma)</li>
      <li>Foglalás törlése gomb dinamiukus megjelenítése</li>
      <li>A scanner pontosabban olvassa be az okmányokat</li>
      <li>Dinamiuks kommunikációs nyelv választó</li>
      <li>Autómata napi zárás</li>
      <li>Kompakt nézet</li>
    </ul>
  </div>
</div>
