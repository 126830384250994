import { Component, OnInit } from '@angular/core';
import {
  DateService,
  FocusService,
  HotelService,
  LanguageService,
} from 'src/app/@services';

@Component({
  selector: 'hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss'],
})
export class HotelComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public focusService: FocusService,
    public langService: LanguageService,
    private dateService: DateService
  ) {}

  protected hotelOOOStart: Date;
  protected hotelOOOEnd: Date;

  public dynamicThe: string = 'a(z)';
  protected communicationLanguages: any[] = [
    {
      value: 'EN',
      label: {
        hu: 'Angol',
        en: 'English',
      },
    },
    {
      value: 'FR',
      label: {
        hu: 'Francia',
        en: 'French',
      },
    },
    {
      value: 'CN',
      label: {
        hu: 'Kínai',
        en: 'Chinese',
      },
    },
    {
      value: 'PL',
      label: {
        hu: 'Lengyel',
        en: 'Polish',
      },
    },
    {
      value: 'HU',
      label: {
        hu: 'Magyar',
        en: 'Hungarian',
      },
    },
    {
      value: 'DE',
      label: {
        hu: 'Német',
        en: 'German',
      },
    },
    {
      value: 'RU',
      label: {
        hu: 'Orosz',
        en: 'Russian',
      },
    },
    {
      value: 'ES',
      label: {
        hu: 'Spanyol',
        en: 'Spanish',
      },
    },
  ];

  protected countries: any[] = [
    { value: 'HU', label: { hu: 'Magyarország', en: 'Hungary' } },
    // Europe
    { value: 'AL', label: { hu: 'Albánia', en: 'Albania' } },
    { value: 'AD', label: { hu: 'Andorra', en: 'Andorra' } },
    { value: 'AT', label: { hu: 'Ausztria', en: 'Austria' } },
    { value: 'BE', label: { hu: 'Belgium', en: 'Belgium' } },
    {
      value: 'BA',
      label: { hu: 'Bosznia-Hercegovina', en: 'Bosnia and Herzegovina' },
    },
    { value: 'BG', label: { hu: 'Bulgária', en: 'Bulgaria' } },
    { value: 'CY', label: { hu: 'Ciprus', en: 'Cyprus' } },
    { value: 'CZ', label: { hu: 'Csehország', en: 'Czech Republic' } },
    { value: 'DK', label: { hu: 'Dánia', en: 'Denmark' } },
    { value: 'GB', label: { hu: 'Egyesült Királyság', en: 'United Kingdom' } },
    { value: 'EE', label: { hu: 'Észtország', en: 'Estonia' } },
    { value: 'BY', label: { hu: 'Fehéroroszország', en: 'Belarus' } },
    { value: 'FI', label: { hu: 'Finnország', en: 'Finland' } },
    { value: 'FR', label: { hu: 'Franciaország', en: 'France' } },
    { value: 'GR', label: { hu: 'Görögország', en: 'Greece' } },
    { value: 'GL', label: { hu: 'Grönland', en: 'Greenland' } },
    { value: 'NL', label: { hu: 'Hollandia', en: 'Netherlands' } },
    { value: 'HR', label: { hu: 'Horvátország', en: 'Croatia' } },
    { value: 'IE', label: { hu: 'Írország', en: 'Ireland' } },
    { value: 'IS', label: { hu: 'Izland', en: 'Iceland' } },
    { value: 'PL', label: { hu: 'Lengyelország', en: 'Poland' } },
    { value: 'LV', label: { hu: 'Lettország', en: 'Latvia' } },
    { value: 'LI', label: { hu: 'Liechtenstein', en: 'Liechtenstein' } },
    { value: 'LT', label: { hu: 'Litvánia', en: 'Lithuania' } },
    { value: 'LU', label: { hu: 'Luxemburg', en: 'Luxembourg' } },
    { value: 'MK', label: { hu: 'Macedónia', en: 'North Macedonia' } },
    { value: 'MT', label: { hu: 'Málta', en: 'Malta' } },
    { value: 'MD', label: { hu: 'Moldova', en: 'Moldova' } },
    { value: 'MC', label: { hu: 'Monaco', en: 'Monaco' } },
    { value: 'DE', label: { hu: 'Németország', en: 'Germany' } },
    { value: 'NO', label: { hu: 'Norvégia', en: 'Norway' } },
    { value: 'IT', label: { hu: 'Olaszország', en: 'Italy' } },
    { value: 'RU', label: { hu: 'Oroszország', en: 'Russia' } },
    { value: 'AM', label: { hu: 'Örményország', en: 'Armenia' } },
    { value: 'PT', label: { hu: 'Portugália', en: 'Portugal' } },
    { value: 'RO', label: { hu: 'Románia', en: 'Romania' } },
    { value: 'ES', label: { hu: 'Spanyolország', en: 'Spain' } },
    { value: 'CH', label: { hu: 'Svájc', en: 'Switzerland' } },
    { value: 'SE', label: { hu: 'Svédország', en: 'Sweden' } },
    { value: 'SK', label: { hu: 'Szlovákia', en: 'Slovakia' } },
    { value: 'SI', label: { hu: 'Szlovénia', en: 'Slovenia' } },
    { value: 'UA', label: { hu: 'Ukrajna', en: 'Ukraine' } },
    { value: 'VA', label: { hu: 'Vatikán', en: 'Vatican City' } },

    // Rest
    { value: 'AF', label: { hu: 'Afganisztán', en: 'Afghanistan' } },
    { value: 'AX', label: { hu: 'Aland', en: 'Aland' } },
    { value: 'DZ', label: { hu: 'Algéria', en: 'Algeria' } },
    {
      value: 'UM',
      label: {
        hu: 'USA Amerikai Csendes-óceáni szigetek',
        en: 'USA Minor Outlying Islands',
      },
    },
    {
      value: 'US',
      label: { hu: 'Amerikai Egyesült Államok', en: 'United States' },
    },
    { value: 'AS', label: { hu: 'Amerikai Szamoa', en: 'American Samoa' } },
    {
      value: 'VI',
      label: { hu: 'Amerikai Virgin-szigetek', en: 'U.S. Virgin Islands' },
    },
    { value: 'AO', label: { hu: 'Angola', en: 'Angola' } },
    { value: 'AI', label: { hu: 'Anguilla', en: 'Anguilla' } },
    { value: 'AQ', label: { hu: 'Antarktisz', en: 'Antarctica' } },
    {
      value: 'AG',
      label: { hu: 'Antigua és Barbuda', en: 'Antigua and Barbuda' },
    },
    { value: 'AR', label: { hu: 'Argentína', en: 'Argentina' } },
    { value: 'AW', label: { hu: 'Aruba', en: 'Aruba' } },
    { value: 'AU', label: { hu: 'Ausztrália', en: 'Australia' } },
    { value: 'AZ', label: { hu: 'Azerbajdzsán', en: 'Azerbaijan' } },
    { value: 'BS', label: { hu: 'Bahama-szigetek', en: 'Bahamas' } },
    { value: 'BH', label: { hu: 'Bahrein', en: 'Bahrain' } },
    { value: 'BD', label: { hu: 'Banglades', en: 'Bangladesh' } },
    { value: 'BB', label: { hu: 'Barbados', en: 'Barbados' } },
    { value: 'BZ', label: { hu: 'Belize', en: 'Belize' } },
    { value: 'BJ', label: { hu: 'Benin', en: 'Benin' } },
    { value: 'BM', label: { hu: 'Bermuda', en: 'Bermuda' } },
    { value: 'BT', label: { hu: 'Bhután', en: 'Bhutan' } },
    { value: 'GW', label: { hu: 'Bissau-Guinea', en: 'Guinea-Bissau' } },
    { value: 'BO', label: { hu: 'Bolívia', en: 'Bolivia' } },
    { value: 'BW', label: { hu: 'Botswana', en: 'Botswana' } },
    {
      value: 'BV',
      label: { hu: 'Norvégia Bouvet-sziget', en: 'Bouvet Island' },
    },
    { value: 'BR', label: { hu: 'Brazília', en: 'Brazil' } },
    {
      value: 'IO',
      label: {
        hu: 'Brit Indiai-óceáni Terület',
        en: 'British Indian Ocean Territory',
      },
    },
    {
      value: 'VG',
      label: { hu: 'Brit Virgin-szigetek', en: 'British Virgin Islands' },
    },
    { value: 'BN', label: { hu: 'Brunei', en: 'Brunei' } },
    { value: 'BF', label: { hu: 'Burkina Faso', en: 'Burkina Faso' } },
    { value: 'BI', label: { hu: 'Burundi', en: 'Burundi' } },
    { value: 'CL', label: { hu: 'Chile', en: 'Chile' } },
    { value: 'KM', label: { hu: 'Comore-szigetek', en: 'Comoros' } },
    { value: 'CK', label: { hu: 'Cook-szigetek', en: 'Cook Islands' } },
    { value: 'CR', label: { hu: 'Costa Rica', en: 'Costa Rica' } },
    { value: 'CW', label: { hu: 'Curaçao', en: 'Curaçao' } },
    { value: 'TD', label: { hu: 'Csád', en: 'Chad' } },
    {
      value: 'ZA',
      label: { hu: 'Dél-afrikai Köztársaság', en: 'South Africa' },
    },
    {
      value: 'GS',
      label: {
        hu: 'Déli-Georgia és Déli-Sandwich-szigetek',
        en: 'South Georgia and the South Sandwich Islands',
      },
    },
    { value: 'SS', label: { hu: 'Dél-Szudán', en: 'South Sudan' } },
    {
      value: 'KR',
      label: { hu: 'Dél-Korea (Koreai Köztársaság)', en: 'South Korea' },
    },
    { value: 'DM', label: { hu: 'Dominikai Közösség', en: 'Dominica' } },
    {
      value: 'DO',
      label: { hu: 'Dominikai Köztársaság', en: 'Dominican Republic' },
    },
    { value: 'DJ', label: { hu: 'Dzsibuti', en: 'Djibouti' } },
    { value: 'EC', label: { hu: 'Ecuador', en: 'Ecuador' } },
    {
      value: 'GQ',
      label: { hu: 'Egyenlítői-Guinea', en: 'Equatorial Guinea' },
    },
    {
      value: 'AE',
      label: { hu: 'Egyesült Arab Emírségek', en: 'United Arab Emirates' },
    },
    { value: 'EG', label: { hu: 'Egyiptom', en: 'Egypt' } },
    { value: 'CI', label: { hu: 'Elefántcsontpart', en: 'Ivory Coast' } },
    { value: 'SV', label: { hu: 'Salvador', en: 'El Salvador' } },
    { value: 'ER', label: { hu: 'Eritrea', en: 'Eritrea' } },
    {
      value: 'MP',
      label: { hu: 'Északi-Mariana-szigetek', en: 'Northern Mariana Islands' },
    },
    {
      value: 'KP',
      label: { hu: 'Észak-Korea (Koreai NDK)', en: 'North Korea' },
    },
    { value: 'ET', label: { hu: 'Etiópia', en: 'Ethiopia' } },
    { value: 'FK', label: { hu: 'Falkland-szigetek', en: 'Falkland Islands' } },
    { value: 'FO', label: { hu: 'Feröer', en: 'Faroe Islands' } },
    { value: 'FJ', label: { hu: 'Fidzsi', en: 'Fiji' } },
    {
      value: 'TF',
      label: {
        hu: 'Francia déli és antarktiszi területek',
        en: 'French Southern and Antarctic Lands',
      },
    },
    { value: 'GF', label: { hu: 'Francia Guyana', en: 'French Guiana' } },
    { value: 'PF', label: { hu: 'Francia Polinézia', en: 'French Polynesia' } },
    { value: 'PH', label: { hu: 'Fülöp-szigetek', en: 'Philippines' } },
    { value: 'GA', label: { hu: 'Gabon', en: 'Gabon' } },
    { value: 'GM', label: { hu: 'Gambia', en: 'Gambia' } },
    { value: 'GH', label: { hu: 'Ghána', en: 'Ghana' } },
    { value: 'GI', label: { hu: 'Gibraltár', en: 'Gibraltar' } },
    { value: 'GD', label: { hu: 'Grenada', en: 'Grenada' } },
    { value: 'GE', label: { hu: 'Grúzia', en: 'Georgia' } },
    { value: 'GP', label: { hu: 'Guadeloupe', en: 'Guadeloupe' } },
    { value: 'GU', label: { hu: 'Guam', en: 'Guam' } },
    { value: 'GT', label: { hu: 'Guatemala', en: 'Guatemala' } },
    { value: 'GG', label: { hu: 'Guernsey Bailiffség', en: 'Guernsey' } },
    { value: 'GN', label: { hu: 'Guinea', en: 'Guinea' } },
    { value: 'GY', label: { hu: 'Guyana', en: 'Guyana' } },
    { value: 'HT', label: { hu: 'Haiti', en: 'Haiti' } },
    {
      value: 'HM',
      label: {
        hu: 'Heard-sziget és McDonald-szigetek',
        en: 'Heard Island and McDonald Islands',
      },
    },
    { value: 'HN', label: { hu: 'Honduras', en: 'Honduras' } },
    { value: 'HK', label: { hu: 'Hongkong', en: 'Hong Kong' } },
    { value: 'IN', label: { hu: 'India', en: 'India' } },
    { value: 'ID', label: { hu: 'Indonézia', en: 'Indonesia' } },
    { value: 'IQ', label: { hu: 'Irak', en: 'Iraq' } },
    { value: 'IR', label: { hu: 'Irán', en: 'Iran' } },
    { value: 'IL', label: { hu: 'Izrael', en: 'Israel' } },
    { value: 'JM', label: { hu: 'Jamaica', en: 'Jamaica' } },
    { value: 'JP', label: { hu: 'Japán', en: 'Japan' } },
    { value: 'YE', label: { hu: 'Jemen', en: 'Yemen' } },
    { value: 'JE', label: { hu: 'Jersey Bailiffség', en: 'Jersey' } },
    { value: 'JO', label: { hu: 'Jordánia', en: 'Jordan' } },
    { value: 'KY', label: { hu: 'Kajmán-szigetek', en: 'Cayman Islands' } },
    { value: 'KH', label: { hu: 'Kambodzsa', en: 'Cambodia' } },
    { value: 'CM', label: { hu: 'Kamerun', en: 'Cameroon' } },
    { value: 'CA', label: { hu: 'Kanada', en: 'Canada' } },
    { value: 'CX', label: { hu: 'Karácsony-sziget', en: 'Christmas Island' } },
    {
      value: 'BQ',
      label: {
        hu: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)',
        en: 'Caribbean Netherlands (Bonaire, Saba, Sint Eustatius)',
      },
    },
    { value: 'QA', label: { hu: 'Katar', en: 'Qatar' } },
    { value: 'KZ', label: { hu: 'Kazahsztán', en: 'Kazakhstan' } },
    { value: 'TL', label: { hu: 'Kelet-Timor', en: 'Timor-Leste' } },
    { value: 'KE', label: { hu: 'Kenya', en: 'Kenya' } },
    { value: 'CN', label: { hu: 'Kína', en: 'China' } },
    { value: 'KG', label: { hu: 'Kirgizisztán', en: 'Kyrgyzstan' } },
    { value: 'KI', label: { hu: 'Kiribati', en: 'Kiribati' } },
    {
      value: 'CC',
      label: { hu: 'Kókusz (Keeling)-szigetek', en: 'Cocos (Keeling) Islands' },
    },
    { value: 'CO', label: { hu: 'Kolumbia', en: 'Colombia' } },
    {
      value: 'CD',
      label: {
        hu: 'Kongói Demokratikus Köztársaság (Zaire)',
        en: 'Democratic Republic of the Congo (Zaire)',
      },
    },
    {
      value: 'CG',
      label: {
        hu: 'Kongói Köztársaság (Kongó)',
        en: 'Republic of the Congo (Congo)',
      },
    },
    { value: 'XK', label: { hu: 'Koszovó', en: 'Kosovo' } },
    {
      value: 'CF',
      label: { hu: 'Közép-Afrika', en: 'Central African Republic' },
    },
    { value: 'CU', label: { hu: 'Kuba', en: 'Cuba' } },
    { value: 'KW', label: { hu: 'Kuvait', en: 'Kuwait' } },
    { value: 'LA', label: { hu: 'Laosz', en: 'Laos' } },
    { value: 'LS', label: { hu: 'Lesotho', en: 'Lesotho' } },
    { value: 'LB', label: { hu: 'Libanon', en: 'Lebanon' } },
    { value: 'LR', label: { hu: 'Libéria', en: 'Liberia' } },
    { value: 'LY', label: { hu: 'Líbia', en: 'Libya' } },
    { value: 'MG', label: { hu: 'Madagaszkár', en: 'Madagascar' } },
    { value: 'MO', label: { hu: 'Makaó', en: 'Macau' } },
    { value: 'MY', label: { hu: 'Malajzia', en: 'Malaysia' } },
    { value: 'MW', label: { hu: 'Malawi', en: 'Malawi' } },
    { value: 'MV', label: { hu: 'Maldív-szigetek', en: 'Maldives' } },
    { value: 'ML', label: { hu: 'Mali', en: 'Mali' } },
    { value: 'IM', label: { hu: 'Man', en: 'Isle of Man' } },
    { value: 'MA', label: { hu: 'Marokkó', en: 'Morocco' } },
    { value: 'MH', label: { hu: 'Marshall-szigetek', en: 'Marshall Islands' } },
    { value: 'MQ', label: { hu: 'Martinique', en: 'Martinique' } },
    { value: 'MR', label: { hu: 'Mauritánia', en: 'Mauritania' } },
    { value: 'MU', label: { hu: 'Mauritius', en: 'Mauritius' } },
    { value: 'YT', label: { hu: 'Mayotte', en: 'Mayotte' } },
    { value: 'MX', label: { hu: 'Mexikó', en: 'Mexico' } },
    { value: 'MM', label: { hu: 'Mianmar', en: 'Myanmar' } },
    { value: 'FM', label: { hu: 'Mikronézia', en: 'Micronesia' } },
    { value: 'MN', label: { hu: 'Mongólia', en: 'Mongolia' } },
    { value: 'ME', label: { hu: 'Montenegró', en: 'Montenegro' } },
    { value: 'MS', label: { hu: 'Montserrat', en: 'Montserrat' } },
    { value: 'MZ', label: { hu: 'Mozambik', en: 'Mozambique' } },
    { value: 'NA', label: { hu: 'Namíbia', en: 'Namibia' } },
    { value: 'NR', label: { hu: 'Nauru', en: 'Nauru' } },
    { value: 'NP', label: { hu: 'Nepál', en: 'Nepal' } },
    { value: 'NI', label: { hu: 'Nicaragua', en: 'Nicaragua' } },
    { value: 'NE', label: { hu: 'Niger', en: 'Niger' } },
    { value: 'NG', label: { hu: 'Nigéria', en: 'Nigeria' } },
    { value: 'NU', label: { hu: 'Niue', en: 'Niue' } },
    { value: 'NF', label: { hu: 'Norfolk-sziget', en: 'Norfolk Island' } },
    { value: 'EH', label: { hu: 'Nyugat-Szahara', en: 'Western Sahara' } },
    { value: 'OM', label: { hu: 'Omán', en: 'Oman' } },
    { value: 'PK', label: { hu: 'Pakisztán', en: 'Pakistan' } },
    { value: 'PW', label: { hu: 'Palau', en: 'Palau' } },
    { value: 'PS', label: { hu: 'Palesztina', en: 'Palestine' } },
    { value: 'PA', label: { hu: 'Panama', en: 'Panama' } },
    { value: 'PG', label: { hu: 'Pápua Új-Guinea', en: 'Papua New Guinea' } },
    { value: 'PY', label: { hu: 'Paraguay', en: 'Paraguay' } },
    { value: 'PE', label: { hu: 'Peru', en: 'Peru' } },
    { value: 'PN', label: { hu: 'Pitcairn-szigetek', en: 'Pitcairn Islands' } },
    { value: 'PR', label: { hu: 'Puerto Rico', en: 'Puerto Rico' } },
    { value: 'RE', label: { hu: 'Réunion', en: 'Réunion' } },
    { value: 'RW', label: { hu: 'Ruanda', en: 'Rwanda' } },
    { value: 'BL', label: { hu: 'Saint-Barthélemy', en: 'Saint Barthélemy' } },
    {
      value: 'KN',
      label: { hu: 'Saint Kitts és Nevis', en: 'Saint Kitts and Nevis' },
    },
    { value: 'LC', label: { hu: 'Saint Lucia', en: 'Saint Lucia' } },
    { value: 'MF', label: { hu: 'Saint-Martin', en: 'Saint Martin' } },
    {
      value: 'PM',
      label: {
        hu: 'Saint-Pierre és Miquelon',
        en: 'Saint Pierre and Miquelon',
      },
    },
    {
      value: 'VC',
      label: {
        hu: 'Saint Vincent és a Grenadine-szigetek',
        en: 'Saint Vincent and the Grenadines',
      },
    },
    { value: 'SB', label: { hu: 'Salamon-szigetek', en: 'Solomon Islands' } },
    { value: 'SM', label: { hu: 'San Marino', en: 'San Marino' } },
    {
      value: 'ST',
      label: { hu: 'Sao Tomé és Príncipe', en: 'São Tomé and Príncipe' },
    },
    { value: 'SC', label: { hu: 'Seychelle-szigetek', en: 'Seychelles' } },
    { value: 'SL', label: { hu: 'Sierra Leone', en: 'Sierra Leone' } },
    { value: 'SX', label: { hu: 'Sint Maarten', en: 'Sint Maarten' } },
    {
      value: 'SJ',
      label: {
        hu: 'Norvégia Spitzbergák és Jan Mayen-sziget',
        en: 'Svalbard and Jan Mayen',
      },
    },
    { value: 'LK', label: { hu: 'Srí Lanka', en: 'Sri Lanka' } },
    { value: 'SR', label: { hu: 'Suriname', en: 'Suriname' } },
    { value: 'WS', label: { hu: 'Szamoa', en: 'Samoa' } },
    { value: 'SA', label: { hu: 'Szaúd-Arábia', en: 'Saudi Arabia' } },
    { value: 'SN', label: { hu: 'Szenegál', en: 'Senegal' } },
    { value: 'SH', label: { hu: 'Szent Ilona', en: 'Saint Helena' } },
    { value: 'RS', label: { hu: 'Szerbia', en: 'Serbia' } },
    { value: 'SG', label: { hu: 'Szingapúr', en: 'Singapore' } },
    { value: 'SY', label: { hu: 'Szíria', en: 'Syria' } },
    { value: 'SO', label: { hu: 'Szomália', en: 'Somalia' } },
    { value: 'SD', label: { hu: 'Szudán', en: 'Sudan' } },
    { value: 'SZ', label: { hu: 'Szváziföld', en: 'Eswatini' } },
    { value: 'TJ', label: { hu: 'Tádzsikisztán', en: 'Tajikistan' } },
    { value: 'TW', label: { hu: 'Tajvan', en: 'Taiwan' } },
    { value: 'TZ', label: { hu: 'Tanzánia', en: 'Tanzania' } },
    { value: 'TH', label: { hu: 'Thaiföld', en: 'Thailand' } },
    { value: 'TG', label: { hu: 'Togo', en: 'Togo' } },
    { value: 'TK', label: { hu: 'Tokelau-szigetek', en: 'Tokelau' } },
    { value: 'TO', label: { hu: 'Tonga', en: 'Tonga' } },
    { value: 'TR', label: { hu: 'Törökország', en: 'Turkey' } },
    {
      value: 'TT',
      label: { hu: 'Trinidad és Tobago', en: 'Trinidad and Tobago' },
    },
    { value: 'TN', label: { hu: 'Tunézia', en: 'Tunisia' } },
    {
      value: 'TC',
      label: {
        hu: 'Turks- és Caicos-szigetek',
        en: 'Turks and Caicos Islands',
      },
    },
    { value: 'TV', label: { hu: 'Tuvalu', en: 'Tuvalu' } },
    { value: 'TM', label: { hu: 'Türkmenisztán', en: 'Turkmenistan' } },
    { value: 'UG', label: { hu: 'Uganda', en: 'Uganda' } },
    { value: 'NC', label: { hu: 'Új-Kaledónia', en: 'New Caledonia' } },
    { value: 'NZ', label: { hu: 'Új-Zéland', en: 'New Zealand' } },
    { value: 'UY', label: { hu: 'Uruguay', en: 'Uruguay' } },
    { value: 'UZ', label: { hu: 'Üzbegisztán', en: 'Uzbekistan' } },
    { value: 'VU', label: { hu: 'Vanuatu', en: 'Vanuatu' } },
    { value: 'VE', label: { hu: 'Venezuela', en: 'Venezuela' } },
    { value: 'VN', label: { hu: 'Vietnám', en: 'Vietnam' } },
    { value: 'WF', label: { hu: 'Wallis és Futuna', en: 'Wallis and Futuna' } },
    { value: 'ZM', label: { hu: 'Zambia', en: 'Zambia' } },
    { value: 'ZW', label: { hu: 'Zimbabwe', en: 'Zimbabwe' } },
    { value: 'CV', label: { hu: 'Zöld-foki Köztársaság', en: 'Cape Verde' } },
  ];

  protected prepaymentTypes: any = [
    {
      label: { hu: 'Előleg típusa', en: 'Prepayment type' },
      disabled: true,
      selected: true,
      value: null,
    },
    {
      label: { hu: 'Százalék', en: 'Percentage' },
      value: 'PERCENT',
    },
    {
      label: { hu: 'Fix ár', en: 'Flat cost' },
      value: 'FLAT',
    },
  ];

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }

  public autoConcludeChanged(status: boolean): void {
    this.hotelService.autoConclude(status);
  }
  public delayedConcludeChanged(status: boolean): void {
    this.hotelService.delayedConclude(status);
  }
  public autoArriveChanged(status: boolean): void {
    this.hotelService.autoArrive(status);
  }
  public autoDepartChanged(status: boolean): void {
    this.hotelService.autoDepart(status);
  }

  public clearPrepayment(status: boolean) {
    if (status) return;
    this.hotelService.localPrepayment.prepayment_flat = null;
    this.hotelService.localPrepayment.prepayment_percent = null;
    this.hotelService.localPrepayment.prepayment_type = null;
  }

  public editPrepayment(): void {
    this.hotelService.editPrepayment();
  }

  public setPrepaymentPrice(): void {
    if (this.hotelService.localPrepayment.prepayment_type === 'FLAT') {
      this.hotelService.localPrepayment.prepayment_percent = null;
    } else {
      this.hotelService.localPrepayment.prepayment_flat = null;
    }
  }

  public toggleCommunicationLang(state: boolean, lang: string): void {
    if (state) {
      this.hotelService.addCommunicationLanguage(lang);
    } else {
      this.hotelService.removeCommunicationLanguage(lang);
    }
  }

  public toggleCountry(state: boolean, country: string): void {
    if (state) {
      this.hotelService.addCountry(country);
    } else {
      this.hotelService.removeCountry(country);
    }
  }

  public addOOO(): void {
    this.hotelService.addHotelOOO({
      start_date: this.dateService.formatDate(this.hotelOOOStart),
      end_date: this.dateService.formatDate(this.hotelOOOEnd),
    });
  }

  public deleteOOO(id: number): void {
    this.hotelService.deleteHotelOOO(id).catch(() => {});
  }

  public setEndDate(): void {
    if (this.hotelOOOEnd) return;

    this.hotelOOOEnd = this.hotelOOOStart;
  }
}
