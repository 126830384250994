import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargeInterface, IResponse } from '../@Interfaces';
import { ChargePaymentResponse } from '../@Interfaces/charge.interface';
import { BookingService } from './booking.service';
import { ConfigService } from './config.service';
import { HotelService } from './hotel.service';

@Injectable({
  providedIn: 'root',
})
export class ChargeService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private bookingService: BookingService,
    private configService: ConfigService
  ) {}

  /* <-- API CALLS --> */

  public async getCharges(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): Promise<ChargeInterface[]> {
    const hotel_id = hotelId ?? this.hotelService.selectedHotelId;
    const booking_id =
      bookingId ?? this.bookingService.selectedBooking.booking_id;
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<ChargeInterface[]>>(
          `${this.configService.server}api/hotels/${hotel_id}/bookings/${booking_id}/charges`
        )
        .subscribe({
          next: (res: IResponse<ChargeInterface[]>) => {
            resolve(res.data);
          },
          error: (err) => {
            console.log('charges err ', err);
            reject();
          },
        });
    });
  }

  public async postCharge(charge: ChargeInterface): Promise<void> {
    delete charge.booking_id;
    delete charge.totalPrice;
    delete charge.payment_date;
    delete charge.payment_method;
    delete charge.paid;
    delete charge.paid_by;
    delete charge.company_payment;
    delete charge.created_date;
    delete charge.intermediary;
    delete charge.currency;

    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges`,
          charge
        )
        .subscribe({
          next: (res: IResponse) => {
            console.log('charge res ', res);
            resolve();
          },
          error: (err: any) => {
            console.log('charge err ', err);

            reject();
          },
        });
    });
  }

  public async editCharge(charge: ChargeInterface): Promise<void> {
    const tempChargeId = charge.charge_id;
    delete charge.booking_id;
    delete charge.charge_id;
    delete charge.room_id;
    delete charge.currency;
    delete charge.totalPrice;
    delete charge.payment_date;
    delete charge.payment_method;
    delete charge.paid;
    delete charge.paid_by;
    delete charge.company_payment;
    delete charge.created_date;
    delete charge.intermediary;

    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges/${tempChargeId}`,
          charge
        )
        .subscribe({
          next: (res: IResponse) => {
            console.log('charge res ', res);

            resolve();
          },
          error: (err: any) => {
            console.log('charge err ', err);

            reject();
          },
        });
    });
  }

  public async payCharge(
    charge: any,
    bookingId: number = null
  ): Promise<number[]> {
    const booking_id =
      bookingId ?? this.bookingService?.selectedBooking?.booking_id;
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse<ChargePaymentResponse>>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${booking_id}/charges/pay`,
          charge
        )
        .subscribe({
          next: (res: IResponse<ChargePaymentResponse>) => {
            resolve(res.data.charges);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteCharge(chargeId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges/${chargeId}`,
          {}
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (err: HttpErrorResponse) => {
            reject();
          },
        });
    });
  }

  /* <-- API CALLS --> */
}
