<div class="hotel-wrapper">
  <div class="main-content">
    <h1>{{ langService.dictionary["accommodation"][langService.lang] }}</h1>
    <p *ngIf="hotelService.selectedHotel">
      {{ hotelService.selectedHotel.name }}
    </p>
    <form #hotelAdminForm="ngForm">
      <fieldset class="hotel">
        <legend>
          {{ langService.dictionary["accommodation"][langService.lang] }}
        </legend>
        <custom-input
          *ngIf="hotelService.selectedHotel"
          [type]="'text'"
          id="hotel_name"
          [name]="'hotel_name'"
          [(ngModel)]="hotelService.selectedHotel.name"
          [label]="langService.dictionary['hotel-name'][langService.lang]"
          [disabled]="true"
        />
        <custom-input
          *ngIf="hotelService.selectedHotel"
          [type]="'text'"
          id="id_ntak"
          [name]="'id_ntak'"
          [(ngModel)]="hotelService.selectedHotel.id_ntak"
          [label]="langService.dictionary['NTAK-id'][langService.lang]"
          [disabled]="true"
        />
        <custom-input
          *ngIf="hotelService.selectedHotel"
          [type]="'text'"
          id="ifa"
          [name]="'ifa'"
          [(ngModel)]="hotelService.selectedHotel.IFA"
          [label]="
            langService.dictionary['IFA'][langService.lang] +
            (hotelService.selectedHotel.flat_IFA ? ' (Ft)' : ' (%)')
          "
          [disabled]="true"
        />
      </fieldset>
      <fieldset class="automations">
        <legend>
          {{ langService.dictionary["automation"][langService.lang] }}
        </legend>
        <custom-checkbox
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="hotelService.isAutoArrive"
          (userInput)="autoArriveChanged($event)"
          [name]="'auto_arrive'"
          [label]="langService.dictionary['auto-arrive'][langService.lang]"
        />
        <custom-checkbox
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="hotelService.isAutoDepart"
          (userInput)="autoDepartChanged($event)"
          [name]="'auto_depart'"
          [label]="langService.dictionary['auto-depart'][langService.lang]"
        />
        <custom-checkbox
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="hotelService.isAutoConclude"
          (userInput)="autoConcludeChanged($event)"
          [name]="'auto_conclude'"
          [label]="langService.dictionary['auto-conclude'][langService.lang]"
        />
        <custom-checkbox
          *ngIf="hotelService.isAutoConclude"
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="hotelService.isDelayedConclude"
          (userInput)="delayedConcludeChanged($event)"
          [name]="'delayed_conclude'"
          [label]="langService.dictionary['delayed-conclude'][langService.lang]"
        />
      </fieldset>

      <fieldset class="prepayment">
        <legend>
          {{ langService.dictionary["prepayment"][langService.lang] }}
        </legend>

        <div class="input-wrapper">
          <custom-checkbox
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.localPrepayment.enabled"
            (ngModelChange)="clearPrepayment($event)"
            [name]="'prepayment_enabled'"
            [label]="langService.dictionary['prepayment'][langService.lang]"
          />
          <custom-select
            class="form-dropdown-select"
            *ngIf="hotelService.localPrepayment.enabled"
            [name]="'prepayment_type'"
            [(ngModel)]="hotelService.localPrepayment.prepayment_type"
            (ngModelChange)="setPrepaymentPrice()"
            [label]="
              langService.dictionary['prepayment-type'][langService.lang] + '*'
            "
            [options]="prepaymentTypes"
          />
          <custom-input
            *ngIf="hotelService.localPrepayment.prepayment_type === 'FLAT'"
            [type]="'number'"
            [name]="'prepayment_flat'"
            [disabled]="!hotelService.localPrepayment.enabled"
            [(ngModel)]="hotelService.localPrepayment.prepayment_flat"
            [label]="langService.dictionary['amount'][langService.lang] + '*'"
          />
          <custom-input
            *ngIf="hotelService.localPrepayment.prepayment_type === 'PERCENT'"
            [type]="'number'"
            [name]="'prepayment_percent'"
            [disabled]="!hotelService.localPrepayment.enabled"
            [(ngModel)]="hotelService.localPrepayment.prepayment_percent"
            [label]="langService.dictionary['amount'][langService.lang] + '*'"
          />
        </div>

        <div class="button-wrapper">
          <button
            class="secondary-button"
            type="button"
            [disabled]="
              hotelService.localPrepayment.enabled &&
              (!hotelService.localPrepayment.prepayment_type ||
                (!hotelService.localPrepayment.prepayment_flat &&
                  !hotelService.localPrepayment.prepayment_percent))
            "
            (click)="editPrepayment()"
          >
            {{ langService.dictionary["save"][langService.lang] }}
          </button>
        </div>
      </fieldset>
      <fieldset class="hotel-ooo">
        <legend>
          {{ langService.dictionary["hotel-ooo"][langService.lang] }}
        </legend>
        <div class="date-list-wrapper">
          <div
            class="date-card"
            *ngFor="let oooDates of hotelService.hotelOOODates"
          >
            <div class="date-range">
              <span class="date">{{ oooDates.start_date }}.</span
              ><span class="hyphen">-</span
              ><span class="date">{{ oooDates.end_date }}.</span>
            </div>
            <button (click)="deleteOOO(oooDates.hotel_ooo_id)">
              <i class="delete-icon"></i>
            </button>
          </div>
        </div>
        <div class="ooo-actions">
          <div class="date-wrapper">
            <date-picker
              [(ngModel)]="hotelOOOStart"
              [name]="'hotelOOOStart'"
              [_value]="hotelOOOStart"
              label="{{
                langService.dictionary['start-date'][langService.lang]
              }}"
              (change)="setEndDate()"
            ></date-picker>

            <date-picker
              [(ngModel)]="hotelOOOEnd"
              name="'hotelOOOEnd'"
              [_value]="hotelOOOEnd"
              label="{{ langService.dictionary['end-date'][langService.lang] }}"
            ></date-picker>
          </div>
          <button
            class="primary-button"
            (click)="addOOO()"
            [disabled]="
              !hotelOOOStart ||
              !hotelOOOEnd ||
              hotelOOOEnd?.getTime() - hotelOOOStart?.getTime() < 0
            "
          >
            + {{ langService.dictionary["add"][langService.lang] }}
          </button>
        </div>
      </fieldset>
      <fieldset class="communication-languages">
        <legend>
          {{
            langService.dictionary["communication-language"][langService.lang]
          }}
        </legend>
        <ng-container *ngFor="let commLang of communicationLanguages">
          <custom-checkbox
            *ngIf="hotelService.selectedHotel?.languages"
            type="checkbox"
            [_value]="
              hotelService.selectedHotel.languages.includes(commLang.value)
            "
            (userInput)="toggleCommunicationLang($event, commLang.value)"
            name="commLang.value"
            [label]="commLang.label[langService.lang]"
          />
        </ng-container>
      </fieldset>
      <fieldset class="countries">
        <legend>
          {{ langService.dictionary["countries"][langService.lang] }}
        </legend>
        <ng-container *ngFor="let country of countries">
          <custom-checkbox
            *ngIf="hotelService.selectedHotel?.countries"
            [_value]="
              hotelService.selectedHotel.countries.includes(country.value)
            "
            (userInput)="toggleCountry($event, country.value)"
            [name]="country.value"
            [label]="country.label[langService.lang]"
          />
        </ng-container>
      </fieldset>
    </form>
  </div>
</div>
