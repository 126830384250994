import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService, RouterService } from 'src/app/@services';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'rooms-ooo',
  templateUrl: './rooms-ooo.component.html',
  styleUrls: ['./rooms-ooo.component.scss'],
})
export class RoomsOooComponent implements OnInit, OnDestroy {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private dateService: DateService,
    private routerService: RouterService,
    public langService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
    this.roomSub = this.routerService.activeRoom$.subscribe((roomId) => {
      if (!roomId) return;

      this.roomService.roomOOO = [];
      this.roomService.roomOOS = [];
      this.startDate = null;
      this.endDate = null;

      this.roomService.getRoomOOO(Number(roomId)).catch(() => {});
    });
  }
  public dynamicThe: string = 'a(z)';
  public floors = [-1, 0, 1, 2];
  public startDate: Date;
  public endDate: Date;

  private roomSub: Subscription;

  public deselectRoom(): void {
    this.routerService.removeQueryParam(['room']);
  }

  public cancel(): void {}

  public deleteOOO(oooId: number): void {
    this.roomService.deleteRoomOOO(oooId);
  }

  public addOOO(): void {
    const startDate = this.dateService.formatDate(this.startDate);
    const endDate = this.dateService.formatDate(this.endDate);
    const requestBody = {
      room_id: this.roomService.selectedRoom.room_id,
      start_date: startDate,
      end_date: endDate,
    };

    this.roomService.createRoomOOO(requestBody).catch(() => {});
  }

  public setEndDate(): void {
    if (this.endDate) return;

    this.endDate = this.startDate;
  }

  ngOnDestroy(): void {
    this.roomSub.unsubscribe();
  }
}
