import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardResInterface, IResponse } from '../@Interfaces';
import { ConfigService } from './config.service';
import { HotelService } from './hotel.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private hotelService: HotelService
  ) {}

  public totalTasks: number;
  public completedTasks: number = 0;

  public async getDashboard(): Promise<DashboardResInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<DashboardResInterface>>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/dashboard`
        )
        .subscribe({
          next: (res: IResponse<DashboardResInterface>) => {
            const dashboard = res.data;
            if (!this.totalTasks)
              this.totalTasks =
                dashboard.arriving.length + dashboard.departing.length;
            resolve(dashboard);
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
