import { Component, OnInit } from '@angular/core';
import { LanguageService, RouterService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(
    public focusService: FocusService,
    public routerService: RouterService,
    public langService: LanguageService
  ) {}

  ngOnInit(): void {}

  public selectedSubPage: string = 'hotels';
  public roomLimit: number = 4;
  public isEditing: boolean = false;

  public selectSubPage(page: string) {
    this.selectedSubPage = page;
    this.routerService.absoluteNavigation('admin/' + page);
    this.focusService.selectedSubMenu = page;
    setTimeout(() => {
      this.routerService.removeQueryParam(['service', 'room']);
    }, 10);
  }

  public newRoom(form: any) {
    console.log(form.value);
  }

  public focusOutButton(event: any) {
    if (!event.srcElement.value) {
      event.srcElement.parentElement.classList.remove('focused');
    }
  }
}
