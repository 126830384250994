import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryFilter',
})
export class CountryFilterPipe implements PipeTransform {
  transform(
    options: {
      label: { hu: string; en: string };
      value?: any;
      selected?: boolean;
      disabled?: boolean;
    }[],
    preferences: any[]
  ): any[] {
    if (!preferences) return null;
    return options.filter(
      (option) => !option.value || preferences.includes(option.value) // Include if value is missing or matches preferences
    );
  }
}
