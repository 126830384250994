import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  public server: string = environment.url;

  public async getVersion(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http
        .get<{ version: string }>(`${this.server}api/version`)
        .subscribe({
          next: (res: { version: string }) => {
            resolve(res.version);
          },
          error: () => {
            console.log('cannot get version');
            reject();
          },
        });
    });
  }
}
