import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponse, ServiceInterface } from '../@Interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../@dialogs/confirmation/confirmation.dialog';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  constructor(
    private http: HttpClient,
    private matDialog: MatDialog,
    private configService: ConfigService
  ) {}
  public services: ServiceInterface[];
  public selectedService: ServiceInterface;

  private selectedHotelId: string;

  public getServices(hotelId: string): void {
    const hotel_id = hotelId ?? this.selectedHotelId;
    this.selectedHotelId = hotel_id;
    this.http
      .get<IResponse<ServiceInterface[]>>(
        `${this.configService.server}api/hotels/${hotel_id}/services`
      )
      .subscribe({
        next: (res: IResponse<ServiceInterface[]>) => {
          this.services = res.data;
        },
        error: () => {},
      });
  }

  public editService(service: any, serviceId: string): Promise<void> {
    if (service.service_id) delete service.service_id;
    if (service.hotel_id) delete service.hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse<ServiceInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/services/${serviceId}`,
          service
        )
        .subscribe({
          next: (res: IResponse<ServiceInterface[]>) => {
            this.services = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public newService(service: any): Promise<void> {
    if (service.service_id) delete service.service_id;
    if (service.hotel_id) delete service.hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<ServiceInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/services`,
          service
        )
        .subscribe({
          next: (res: IResponse<ServiceInterface[]>) => {
            this.services = res.data;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public deleteService(service: any): void {
    this.matDialog
      .open(ConfirmationDialog)
      .afterClosed()
      .subscribe((choice) => {
        if (!choice) return;

        this.http
          .delete<IResponse<ServiceInterface[]>>(
            `${this.configService.server}api/hotels/${this.selectedHotelId}/services/` +
              service.service_id
          )
          .subscribe({
            next: (res: IResponse<ServiceInterface[]>) => {
              this.services = res.data;
            },
            error: () => {},
          });
      });
  }

  public selectService(serviceId: string): void {
    this.selectedService = this.services.find(
      (service: ServiceInterface) => service.service_id === Number(serviceId)
    );
  }
}
