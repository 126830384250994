<div class="rooms-ooo-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["oos"][langService.lang] }}</h1>
    <!-- <p>Használaton kívül lévő, DE kiadható szobák</p> -->
  </div>
  <div class="hint" *ngIf="!roomService.selectedRoom">
    <span>{{ langService.dictionary["select-room"][langService.lang] }}</span>
  </div>

  <div class="date-list-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-card" *ngFor="let oos of roomService.roomOOS">
      <div class="date-range">
        <span class="date">{{ oos.start_date }}.</span
        ><span class="hyphen">-</span
        ><span class="date">{{ oos.end_date }}.</span>
      </div>
      <button (click)="deleteOOS(oos.oos_id)">
        <i class="delete-icon"></i>
      </button>
    </div>
    <div *ngIf="!roomService.roomOOS.length" class="hint-wrapper">
      <span>{{ langService.dictionary["oos-none"][langService.lang] }}</span>
    </div>
  </div>
  <div class="add-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-range">
      <date-picker
        [(ngModel)]="startDate"
        name="inputoosStartDate"
        [_value]="startDate"
        label="{{ langService.dictionary['start-date'][langService.lang] }}"
        (change)="setEndDate()"
      ></date-picker>

      <date-picker
        [(ngModel)]="endDate"
        name="inputoosEndDate"
        [_value]="endDate"
        label="{{ langService.dictionary['end-date'][langService.lang] }}"
      ></date-picker>
    </div>
    <button
      (click)="addOOS()"
      class="primary-button"
      [disabled]="
        !startDate || !endDate || endDate?.getTime() - startDate?.getTime() < 0
      "
    >
      + {{ langService.dictionary["add"][langService.lang] }}
    </button>
  </div>
</div>
