import { Component } from '@angular/core';
import { AuthService, LanguageService, UserService } from 'src/app/@services';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  constructor(
    private authService: AuthService,
    public userService: UserService,
    public langService: LanguageService
  ) {}

  public signOut(): void {
    this.authService.signOut();
  }

  protected changeDoubleConclude(status: boolean): void {
    if (status) {
      this.userService.turnOnDoubleConclude();
    } else {
      this.userService.turnOffDoubleConclude();
    }
  }

  protected updateName(): void {
    this.userService.updateUserName();
  }

  protected changeTheme(isDarkMode: boolean): void {
    this.userService.changeTheme(isDarkMode);
  }
}
